import { Box, Grid, Typography } from "@mui/material";
import PercentageWheel from "../../../../CommonComponents/PercentageWheel";
import chatgptIcon from "../../../../assets/images/chatgpt.png";
import perplexityIcon from "../../../../assets/images/perplexity.png";
import geminiIcon from "../../../../assets/images/gemini.png";
import { mapLLmLabels } from "../../utils/DataUtils";

const LLMIconMapping = {
  ChatGpt: chatgptIcon,
  Gemini: geminiIcon,
  Perplexity: perplexityIcon,
};
const RankingWithWheel = ({
  isVertical,
  rankings,
  grid,
  wheelSize,
  gap = 2,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: isVertical ? "column" : "row",
        justifyContent: "space-between",
        gap: 10,
      }}
    >
      <Grid container spacing={gap}>
        {rankings.map((ranking, index) => (
          <Grid item xs={grid}>
            <Box
              style={{
                display: "flex",
                flexDirection: isVertical ? "row" : "column-reverse",
                alignItems: "center",
                gap: isVertical ? 12 : 20,
              }}
              key={index}
            >
              <PercentageWheel
                percentage={ranking.score}
                width={wheelSize ? wheelSize : isVertical ? 60 : 96}
                height={wheelSize ? wheelSize : isVertical ? 60 : 96}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 4,
                }}
              >
                {(ranking.icon || LLMIconMapping[ranking.label]) && (
                  <img
                    src={ranking.icon || LLMIconMapping[ranking.label]}
                    alt={index}
                    style={{ width: 14, height: 14 }}
                  />
                )}
                <Typography style={{ fontSize: 14, fontWeight: 400 }}>
                  {LLMIconMapping[ranking.label]
                    ? mapLLmLabels[ranking.label]
                    : ranking.label}
                </Typography>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default RankingWithWheel;
