import React from "react";

const StarIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.667 6.44664C14.6249 6.3247 14.5483 6.21759 14.4465 6.13831C14.3448 6.05903 14.2222 6.01097 14.0936 5.99997L10.3003 5.44664L8.60031 1.99997C8.54572 1.88726 8.46049 1.7922 8.35437 1.72569C8.24825 1.65918 8.12555 1.6239 8.00031 1.6239C7.87507 1.6239 7.75237 1.65918 7.64625 1.72569C7.54014 1.7922 7.4549 1.88726 7.40031 1.99997L5.70031 5.43997L1.90698 5.99997C1.78359 6.01751 1.66759 6.06928 1.57214 6.14941C1.47669 6.22954 1.40562 6.33482 1.36698 6.45331C1.33161 6.56909 1.32843 6.69232 1.3578 6.80977C1.38716 6.92722 1.44795 7.03446 1.53364 7.11997L4.28698 9.78664L3.62031 13.5733C3.59651 13.6983 3.60897 13.8275 3.65622 13.9456C3.70348 14.0637 3.78355 14.1659 3.88698 14.24C3.98779 14.312 4.10669 14.3546 4.23034 14.3628C4.35399 14.3711 4.47749 14.3447 4.58698 14.2866L8.00031 12.5066L11.4003 14.2933C11.4939 14.3461 11.5996 14.3737 11.707 14.3733C11.8482 14.3738 11.9859 14.3295 12.1003 14.2466C12.2037 14.1725 12.2838 14.0704 12.3311 13.9523C12.3783 13.8341 12.3908 13.705 12.367 13.58L11.7003 9.79331L14.4536 7.12664C14.5499 7.04509 14.621 6.93791 14.6588 6.81754C14.6966 6.69717 14.6994 6.56856 14.667 6.44664ZM10.567 9.11331C10.4888 9.18893 10.4303 9.28253 10.3966 9.38596C10.3629 9.48938 10.355 9.59947 10.3736 9.70664L10.8536 12.5L8.34698 11.1666C8.25052 11.1153 8.14292 11.0884 8.03364 11.0884C7.92437 11.0884 7.81677 11.1153 7.72031 11.1666L5.21364 12.5L5.69364 9.70664C5.71227 9.59947 5.70439 9.48938 5.67069 9.38596C5.63699 9.28253 5.5785 9.18893 5.50031 9.11331L3.50031 7.11331L6.30698 6.70664C6.41498 6.69162 6.51764 6.65033 6.60598 6.58641C6.69431 6.52248 6.76562 6.43787 6.81364 6.33997L8.00031 3.79997L9.25364 6.34664C9.30167 6.44453 9.37298 6.52915 9.46131 6.59308C9.54965 6.657 9.65231 6.69829 9.76031 6.71331L12.567 7.11997L10.567 9.11331Z"
      fill={color}
    />
  </svg>
);

export default StarIcon;
