import { Box, Divider, Grid, Typography } from "@mui/material";
import CriteriaRow from "./Components/CriteriaRow/CriteriaRow";

function capitalizeFirstLetter(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

const ComparisionTable = ({ brands, scores }) => {
  const data = Object.entries(scores).map(([label, values]) => {
    if (typeof values !== "object" || values === null) {
      return { label, values: {} };
    }

    const valuesObj = brands.reduce((acc, brand) => {
      const brandKey = Object.keys(values).find(
        (key) =>
          typeof key === "string" && key.toLowerCase() === brand.toLowerCase()
      );
      acc[brand] = brandKey ? values[brandKey] : null;
      return acc;
    }, {});

    return { label, values: valuesObj };
  });

  return (
    <Box
      sx={{
        overflowX: "scroll",
        width: "100%",
        padding: 2,
        "&::-webkit-scrollbar": {
          height: "3px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888",
          borderRadius: "4px",
        },
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 20,
          minWidth: "600px",
        }}
      >
        <Grid
          container
          width={"100%"}
          sx={{
            justifyContent: { sm: "space-between" },
            alignItems: "center",
          }}
        >
          <Grid item xs={4} sm={5}>
            <Typography
              color="text.tertiary"
              style={{ fontWeight: 400, fontSize: 12 }}
            >
              Criteria
            </Typography>
          </Grid>

          <Grid
            item
            xs={6.5}
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: 5,
            }}
          >
            {brands?.map((title) => (
              <div
                key={title}
                style={{
                  width: "152px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  color="text.primary"
                  style={{ fontWeight: 400, fontSize: 12 }}
                >
                  {capitalizeFirstLetter(title)}
                </Typography>
              </div>
            ))}
          </Grid>
        </Grid>
        <div>
          {data.map((row, index) => (
            <div key={row.label}>
              <CriteriaRow criteria={row.label} values={row.values} />
              {index < data.length - 1 && (
                <Divider
                  sx={{
                    borderColor: "#404040",
                    width: "100%",
                    margin: "6px 0px",
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default ComparisionTable;
