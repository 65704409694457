import ScoreTypography from "../../components/ScoreTypography/ScoreTypography";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { mapLLmLabels } from "../../../../../AEO/utils/DataUtils";

const WebView = ({
  scores,
  brand_authority_score,
  selectedLLM,
  LLMs,
  gridValues,
}) => {
  return (
    <>
      <Grid container width={"100%"}>
        <Grid item xs={4}>
          <Typography
            color="text.tertiary"
            style={{ fontWeight: 400, fontSize: 12 }}
          >
            LLM
          </Typography>
        </Grid>

        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {["Recognized Presence?", "Sentiment", "Brand Authority Score"].map(
            (title) => (
              <Typography
                color="text.tertiary"
                style={{ fontWeight: 400, fontSize: 12 }}
              >
                {title}
              </Typography>
            )
          )}
        </Grid>
      </Grid>

      <Divider sx={{ borderColor: "#333333", width: "100%" }} />
      <Grid container width="100%">
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: 5,
            alignItems: "center",
          }}
        >
          <img
            src={LLMs[selectedLLM]}
            style={{ width: 14, height: 14 }}
            alt={selectedLLM}
          />
          <Typography
            color="text.primary"
            style={{ fontWeight: 400, fontSize: 14.55 }}
          >
            {mapLLmLabels[selectedLLM]}
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {gridValues.map((value, index) => (
            <Box
              style={{
                textAlign: value.placement,
              }}
            >
              <ScoreTypography
                score={index <= 1 ? scores[value.label] : brand_authority_score}
                iconType={value.iconType}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
export default WebView;
