import { Card, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CardWithIcon = ({
  icon,
  title,
  tooltipMessagePrimary,
  tooltipMessageSecondary,
  height,
  children,
  padding = "25px",
}) => {
  const theme = useTheme();

  return (
    <Card
      style={{
        borderRadius: 10,
        padding: padding,
        boxShadow: "none",
        height: height,
        position: "relative",
        backgroundColor: theme.palette.background.secondary,
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        width: "100%",
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: 4,
            alignItems: "center",
          }}
        >
          {icon}
          <Typography
            color="text.primary"
            style={{ fontWeight: 500, fontSize: 14 }}
          >
            {title}
          </Typography>
          {tooltipMessagePrimary && (
            <Tooltip
              title={
                <div>
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {tooltipMessagePrimary}
                  </div>
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {tooltipMessageSecondary}
                  </div>
                </div>
              }
            >
              <InfoOutlinedIcon
                sx={{ color: "text.tertiary", width: 15, height: 15 }}
              />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {children}
    </Card>
  );
};
export default CardWithIcon;
