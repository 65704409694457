import { Divider, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";

const RankingTypography = ({ ranking }) => {
  const theme = useTheme();
  return (
    ranking && (
      <Box
        style={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: theme.palette.background.secondary,
        }}
      >
        <Typography
          color="text.primary"
          style={{ fontSize: 30, fontWeight: 450 }}
        >
          <span style={{ fontSize: 14 }}> Your brand is ranked</span> {ranking}
        </Typography>
        <Divider
          sx={{
            height: "1px",
            borderColor: "border.quaternary",
            borderBottomWidth: "1px",
            margin: "10px 0px",
          }}
        />
      </Box>
    )
  );
};
export default RankingTypography;
