import { Button } from "@mui/material";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { TwentyThreeMp } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  addMoreButton: {
    borderRadius: "8.25px !important",

    fontWeight: 400,
    marginTop: "15px !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      boxShadow: "none !important",
    },
    padding: "6.75px 7.5px 6.75px 7.5px !important",
    fontSize: 14,
  },
}));

const AddMoreButton = ({
  onClick,
  title = "Add more",
  showIcon = true,
  disabled,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Button
      disabled={disabled}
      variant="contained"
      className={classes.addMoreButton}
      onClick={onClick}
      sx={{
        backgroundColor: "background.tertiary",
        color: "text.secondary",
        "&:hover": {
          backgroundColor: "background.tertiary",
          color: "text.primary",
        },
        "&:disabled": {
          backgroundColor: theme.palette.background.tertiary,
          color: theme.palette.text.quaternary,
        },
      }}
    >
      {showIcon && (
        <AddCircleOutlineSharpIcon
          sx={{ marginRight: "5px", fontSize: "16px" }}
        />
      )}
      {title}
    </Button>
  );
};
export default AddMoreButton;
