import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { getSentimentColors } from "../../../../../AEO/utils/DataUtils";

const CriteriaScore = ({ score }) => {
  const theme = useTheme();

  return (
    <Typography
      style={{
        height: "45px",
        width: "152px",
        borderRadius: 6,
        display: "flex",
        fontSize: 16,
        fontWeight: 600,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: getSentimentColors(score, theme)[0],
        color: getSentimentColors(score, theme)[1],
      }}
    >
      {score}
    </Typography>
  );
};
export default CriteriaScore;
