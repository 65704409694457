import { Grid, useTheme } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import RadarChartCard from "../../components/RadarChartCard/RadarChartCard";
import RankingWheelsCard from "../../components/RankingWheelsCard/RankingWheelsCard";
import RankingStackedBarCard from "../../components/RankingStackedBarCard/RankingStackedBarCard";
import BarChartsCard from "../../components/BarChartsCard/BarChartsCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";

const ComparisonAnalysis = () => {
  const theme = useTheme();

  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Comparison Analysis"
        description="Compare brand performance against competitors side-by-side, analyzing visibility, ranking, sentiment, and performance. Identify areas for improvement and potential strategic shifts."
        hasGlobalFilters={false}
      >
        <BarChartsCard
          icon={
            <LayersOutlinedIcon
              style={{ width: 15, height: 15 }}
              color={theme.palette.text.primary}
            />
          }
          title="Visibility Score Comparison"
          tooltipPrimary="Shows your brand's visibility score alongside competitors for selected LLMs. Higher scores reflect stronger presence."
          tooltipSecondary="Scores are out of 100."
          height={'100%'}
          hasLLMSelect
        />
        <LineChartCard
          icon={<VisibilityIcon color={theme.palette.text.primary} />}
          title="Visibility Movement"
          tooltipPrimary="Track your brand’s performance score trend over time across selected competitors and LLMs. Use the dropdown to select your preferred LLM."
          hasCaledarSelect
          hasLLMselect
          height={'100%'}
          chartHasXasix
          chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
        />

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <RadarChartCard
              icon={<LayersOutlinedIcon style={{ width: 15, height: 15 }} />}
              title="Competitive Analysis"
              tooltipPrimary="Evaluates your brand’s performance against competitors based on key industry criteria."
              tooltipSecondary="Note: The criteria may change based on the brand’s industry vertical."
              hasLLMselect
              height={560}
            />
          </Grid>
          <Grid item xs={5.85}>
            <RankingWheelsCard
              icon={<VisibilityIcon color={theme.palette.text.primary} />}
              title="Visibility Ranking"
              tooltipPrimary="Displays your brand’s rank against competitors for the selected LLM. Higher scores indicate a stronger position compared to competitors."
              tooltipSecondary="Scores are out of 100."
              hasLLMselect
              height={560}
              hasRankingTypography
              wheelsGrid={6}
              isVertical
              comparsion_type="Competitors"
              wheelSize={100}
              gap={5}
            />
          </Grid>{" "}
        </Grid>

        <RankingStackedBarCard
          icon={<SentimentIcon color={theme.palette.text.primary} />}
          title="Side-By-Side Sentiment Analysis"
          tooltipPrimary="Compares the sentiment distribution (positive, neutral, and negative) across brands."
          tooltipSecondary="Scores total 100%."
          hasLLMSelect
          height={232}
          gridTitle2="Company"
          gridTitle3=""
        />
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default ComparisonAnalysis;
