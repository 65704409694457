import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArticalIcon from "../../../../../../CommonComponents/customIcons/ArticalIcon";
import BlogIcon from "../../../../../../CommonComponents/customIcons/BlogIcon";
import LandingPageIcon from "../../../../../../CommonComponents/customIcons/LandingPageIcon";
import OpenLinkIcon from "../../../../../../CommonComponents/customIcons/OpenLinkIcon";
import SocialMediaIcon from "../../../../../../CommonComponents/customIcons/SocialMediaIcon";
import TypographyTableCell from "../TypographyTableCell/TypographyTableCell";

const SourceTableCell = ({ value, category, navigate: url }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const categoryIcons = {
    article: (
      <ArticalIcon
        color={theme.palette.text.primary}
        backgrounColor={theme.palette.background.tertiary}
      />
    ),
    blog: (
      <BlogIcon
        color={theme.palette.text.primary}
        backgrounColor={theme.palette.background.tertiary}
      />
    ),
    "social media": (
      <SocialMediaIcon
        color={theme.palette.text.primary}
        backgrounColor={theme.palette.background.tertiary}
      />
    ),
    "landing page": (
      <LandingPageIcon
        color={theme.palette.text.primary}
        backgrounColor={theme.palette.background.tertiary}
      />
    ),
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: 4,
        justifyContent: category && "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => window.open(url, "_blank", "noopener,noreferrer")}
    >
      {category && categoryIcons[category?.toLowerCase()]}
      <TypographyTableCell value={value} />
      <OpenLinkIcon color={theme.palette.text.tertiary} />
    </Box>
  );
};
export default SourceTableCell;
