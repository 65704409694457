import { useEffect, useState } from "react";
import axios from "axios";
import { ML_AEO_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import PromptCounter from "../../../../CommonComponents/PromptCounter";

const AeoPromptCounter = () => {
  const [prompt, setPrompt] = useState();
  const { brandId } = useParams();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    axios
      .post(`${ML_AEO_URL}/aeo/prompt-index`, {
        brandId: brandId,
      })
      .then((response) => {
        setLoading(false);
        setPrompt(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user data:", error);
      });
  }, [brandId]);

  return (
    <PromptCounter
      title={"Prompt Index"}
      prompt={prompt}
      loading={loading}
      tooltipMessage={
        "Represents the total number of prompts indexed as 'AI Beats,' reflecting API queries across LLMs and RAG during Analysis Generation."
      }
    />
  );
};
export default AeoPromptCounter;
