import { Typography, useTheme } from "@mui/material";

const TopicTableCell = ({ topic }) => {
  const theme = useTheme();
  return (
    <Typography
      style={{
        fontSize: 12,
        backgroundColor: theme.palette.background.tertiary,
        borderRadius: "30px",
        padding: "5px 8px",
        maxWidth: "fit-content",
      }}
    >
      {topic}
    </Typography>
  );
};
export default TopicTableCell;
