import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

const StatusTableCell = ({ status }) => {
  const theme = useTheme();
  const getStatusColor = {
    inbox: theme.palette.text.brand,
    "in progress": theme.palette.brands.C,
    completed: theme.palette.text.positive,
  };
  return (
    <Typography
      style={{
        maxWidth: "fit-content",
        borderRadius: 11.65,
        display: "flex",
        flexDirection: "row",
        columnGap: 7,
        fontSize: 12,
        fontWeight: 400,
        padding: "2px 7px",
        alignItems: "center",
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.palette.text.primary,
      }}
    >
      <div
        style={{
          width: "5px",
          height: "5px",
          borderRadius: "50%",
          backgroundColor: getStatusColor[status?.toLowerCase()],
        }}
      ></div>{" "}
      {status}
    </Typography>
  );
};
export default StatusTableCell;
