import React from "react";

const LandingPageIcon = ({ color = "#AFB1B7", backgrounColor = "#F5F5F5" }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="26" height="26" rx="13" fill={backgrounColor} />
    <path
      d="M19.2728 10.76C19.2728 10.76 19.2728 10.76 19.2728 10.7267C18.8031 9.44415 17.9508 8.33686 16.8311 7.55458C15.7115 6.7723 14.3786 6.35278 13.0128 6.35278C11.647 6.35278 10.3141 6.7723 9.19449 7.55458C8.07487 8.33686 7.22255 9.44415 6.75281 10.7267C6.75281 10.7267 6.75281 10.7267 6.75281 10.76C6.22823 12.2073 6.22823 13.7927 6.75281 15.24C6.75281 15.24 6.75281 15.24 6.75281 15.2733C7.22255 16.5559 8.07487 17.6631 9.19449 18.4454C10.3141 19.2277 11.647 19.6472 13.0128 19.6472C14.3786 19.6472 15.7115 19.2277 16.8311 18.4454C17.9508 17.6631 18.8031 16.5559 19.2728 15.2733C19.2728 15.2733 19.2728 15.2733 19.2728 15.24C19.7974 13.7927 19.7974 12.2073 19.2728 10.76ZM7.83948 14.3333C7.6083 13.4595 7.6083 12.5405 7.83948 11.6667H9.07948C8.97283 12.5524 8.97283 13.4476 9.07948 14.3333H7.83948ZM8.38615 15.6667H9.31948C9.47596 16.2612 9.69962 16.836 9.98615 17.38C9.33234 16.9346 8.78581 16.3494 8.38615 15.6667ZM9.31948 10.3333H8.38615C8.78006 9.65273 9.31961 9.06765 9.96615 8.62C9.68652 9.16483 9.46957 9.73961 9.31948 10.3333ZM12.3328 18.1333C11.514 17.5325 10.9389 16.6568 10.7128 15.6667H12.3328V18.1333ZM12.3328 14.3333H10.4261C10.3017 13.4488 10.3017 12.5512 10.4261 11.6667H12.3328V14.3333ZM12.3328 10.3333H10.7128C10.9389 9.34318 11.514 8.46755 12.3328 7.86667V10.3333ZM17.6128 10.3333H16.6795C16.523 9.73877 16.2993 9.16396 16.0128 8.62C16.6666 9.06538 17.2131 9.65063 17.6128 10.3333ZM13.6661 7.86667C14.485 8.46755 15.06 9.34318 15.2861 10.3333H13.6661V7.86667ZM13.6661 18.1333V15.6667H15.2861C15.06 16.6568 14.485 17.5325 13.6661 18.1333ZM15.5728 14.3333H13.6661V11.6667H15.5728C15.6972 12.5512 15.6972 13.4488 15.5728 14.3333ZM16.0328 17.38C16.3193 16.836 16.543 16.2612 16.6995 15.6667H17.6328C17.2331 16.3494 16.6866 16.9346 16.0328 17.38ZM18.1595 14.3333H16.9195C16.9737 13.891 17.0004 13.4457 16.9995 13C17.0002 12.5543 16.9735 12.109 16.9195 11.6667H18.1595C18.3907 12.5405 18.3907 13.4595 18.1595 14.3333Z"
      fill={color}
    />
  </svg>
);

export default LandingPageIcon;
