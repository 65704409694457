import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import OpenLinkIcon from "../../../../../../CommonComponents/customIcons/OpenLinkIcon";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import { ML_AEO_URL } from "../../../../../../api";

const topics = [
  { label: "Digital Banking App", link: "" },
  { label: "Online Banking", link: "" },
  { label: "Savings Accounts", link: "" },
  { label: "Credits Builder", link: "" },
  { label: "Mobile Banking", link: "" },
  { label: "Digital Banking App", link: "" },
  { label: "Digital Banking App", link: "" },
];
const TopicRanking = () => {
  const theme = useTheme();
  const { llm, noData, initialDate: globalInitialDate } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [data, setData] = useState(noData ? topics : []);
  const { brandId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    if (globalInitialDate?.length > 0) {
      const values = {
        brandId,
        start_date:
          globalInitialDate[0] && globalInitialDate[0].format("YYYY-MM-DD"),
        end_date:
          globalInitialDate[1] && globalInitialDate[1].format("YYYY-MM-DD"),
        LLMs: selectedLLM,
      };
      setLoading(true);
      axios
        .post(`${ML_AEO_URL}/aeo/top-ranking-topics`, values)
        .then((response) => {
          setLoading(false);

          const data = response.data;
          const sortedData = data?.sort(
            (a, b) =>
              b.max_visibility_percentage_score -
              a.max_visibility_percentage_score
          );

          setData(sortedData);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
    }
  }, [brandId, selectedLLM, globalInitialDate]);

  return (
    <ChartCard
      title="Topic Ranking"
      tooltipMessagePrimary="Shows your brand’s visibility score across various regions and selected LLM. Higher scores indicate stronger brand presence in each country or region."
      tooltipMessageSecondary="All score out of 100."
      height={276}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      isLoading={loading}
      isError={error}
      icon={<LayersOutlinedIcon style={{ width: 15, height: 15 }} />}
    >
      {data?.map((topic, index) => (
        <div
          style={{ display: "flex", flexDirection: "column", marginTop: 10 }}
        >
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: 2,
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate(
                `/optimizationhub/${brandId}/topic/${topic.topic.replace(
                  /\s+/g,
                  "-"
                )}`
              )
            }
          >
            <Typography
              style={{ fontSize: 12, color: theme.palette.text.tertiary }}
            >
              {index + 1}
            </Typography>
            <Typography style={{ fontSize: 14 }}>{topic.topic}</Typography>
            <OpenLinkIcon color={theme.palette.text.tertiary} />
          </Box>
          {index < data?.length - 1 && (
            <Divider
              sx={{
                height: "0.5px",
                borderColor: "border.quaternary",
                margin: "8px 0px",
              }}
            />
          )}
        </div>
      ))}
    </ChartCard>
  );
};
export default TopicRanking;
