import { Box, Divider, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LLMDropDown from "../AEO/components/LLMDropdown/LLMDropdown";
import CardWithIcon from "../../CommonComponents/CardWithIcon/CardWithIcon";
import EntityRecognitionScore from "./components/EntityRecognitionScore/EntityRecognitionScore";
import Header from "./components/Header/Header";
import SentimentAnalysis from "./components/SentimentAnalysis/SentimentAnalysis";
import BrandVisibility from "./components/BrandVisibility/BrandVisibility";
import OwnedComparision from "./components/OwnedComparision/OwnedComparision";
import EarnedComparision from "./components/EarnedComparision";
import axios from "axios";
import { API_URL } from "../../api";
import dayjs from "dayjs";
import Recommendations from "./components/Recommendations/Recommendations";
import { mapLLmLabels } from "../AEO/utils/DataUtils";


const AeoAssessmentReport = () => {
  const navigate = useNavigate();
  const [selectedLLM, setSelectedLLM] = useState("ChatGpt");
  const [reports, setReports] = useState({});
  const [llmReport, setLlmReport] = useState({});

  const [loading, setLoading] = useState(true);
  const { assessmentDataId } = useParams();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${API_URL}/aeo-assessment/assessment-data/${assessmentDataId}/reports`
      )
      .then((response) => {
        setLoading(false);

        const reports = response.data;
        if (reports[selectedLLM].is_expired) {
          navigate("expired");
        } else {
          setReports(reports);
          setLlmReport(reports[selectedLLM]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user data:", error);
      });
  }, [assessmentDataId]);

  useEffect(() => {
    setLlmReport(reports[selectedLLM]);
  }, [selectedLLM]);
  return (
    !loading && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: "50px 20px", md: "20px 70px" },
          position: "relative",
          overflow: "hidden",
          background: " #1A1A1A",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "10%",
            width: "70%",
            height: "5%",
            background: "#7D39EC",
            filter: "blur(435.71px)",
            opacity: 1,
            zIndex: 0,
          }}
        ></Box>
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
            justifyContent: "center",
            maxWidth: { xs: "335px", sm: "600px", md: "889px" },
            alignItems: "flex-start",
            marginTop: { xs: "20px", md: "209px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
              justifyContent: "center",
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "28px", md: "36px" },
                fontWeight: 500,
                lineHeight: "44px",
                letterSpacing: "-2%",
              }}
            >
              {llmReport?.brands_names && llmReport?.brands_names[0]}'s AI
              Perfomance Report
            </Typography>

            <Typography
              sx={{
                backgroundColor: "#8DA3FA33",
                color: "#D9D9D9",
                padding: "25px",
                borderRadius: "10px",
                fontSize: 16,
              }}
            >
              <span
                style={{
                  display: "block",
                  paddingBottom: 10,
                  fontSize: 20,
                  color: "#FFFFFF",
                }}
              >
                This is a sample assessment of your brand visibility on select
                AI search platforms.
              </span>{" "}
              Using our proprietary interpretability platform which evaluates
              factors such as user persona, complex prompt variation, and
              customer journey, we methodically assess AI’s likelihood of
              mentioning your brand, how it consistently describes your entity,
              and what online sources are influencing it. This is a preliminary
              overview. A complete analysis requires a full setup and more
              computation. To unlock access to our full visibility and
              optimization platform, 
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = `https://www.higoodie.com/#signup`)
                }
              >
                click here.
              </span>
            </Typography>
          </Box>

          <Divider sx={{ borderColor: "#404040", width: "100%" }} />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ fontSize: 14, fontWeight: 450 }}
                color="text.tertiary"
              >
                Displaying Data from{" "}
                {llmReport?.created_at &&
                  dayjs(llmReport?.created_at).format("MMMM DD")}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: 4,
              }}
            >
              <LLMDropDown
                setSelectedLLM={setSelectedLLM}
                selectedLLM={selectedLLM}
                backgroundColorValue="tertiary"
                assessment_llms
              />
            </Grid>
          </Grid>

          <CardWithIcon
            title={`${mapLLmLabels[selectedLLM]} describes ${
              llmReport?.brands_names && llmReport?.brands_names[0]
            } as:`}
            height={"100%"}
          >
            <Typography
              color="text.secondary"
              style={{ fontWeight: 500, fontSize: 14 }}
            >
              {llmReport?.entity_recognition?.description}
            </Typography>
          </CardWithIcon>

          <EntityRecognitionScore selectedLLM={selectedLLM} data={llmReport} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SentimentAnalysis values={llmReport.sentiment_score} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BrandVisibility percentage={llmReport.visibility_score} />
            </Grid>
          </Grid>

          <OwnedComparision
            brands={llmReport.brands_names}
            scores={llmReport.owned_comparison}
          />

          <EarnedComparision
            brands={llmReport.brands_names}
            scores={llmReport.earned_comparison}
          />

          <Recommendations
            data={llmReport}
            currentBrand={llmReport.brands_names[0]}
          />
        </Box>
      </Box>
    )
  );
};
export default AeoAssessmentReport;
