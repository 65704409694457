import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import chatgptIcon from "../../../../assets/images/chatgpt.png";
import perplexityIcon from "../../../../assets/images/perplexity.png";
import geminiIcon from "../../../../assets/images/gemini.png";
import CloseIcon from "@mui/icons-material/Close";

const LLMs = {
  ChatGpt: chatgptIcon,
  Perplexity: perplexityIcon,
  Gemini: geminiIcon,
};

const BrandsList = ({
  brands,
  selectedBrands,
  setSelectedBrands,
  isLLM,
  colorMap = {},
}) => {
  useEffect(() => {
    if (selectedBrands.length === 0 && brands.length > 0) {
      setSelectedBrands([brands[0]]);
    }
  }, [selectedBrands, brands, setSelectedBrands]);
  const handleBrandClick = (brand) => {
    setSelectedBrands((prevSelected) => {
      const newSelection = prevSelected.includes(brand)
        ? prevSelected.filter((b) => b !== brand)
        : [...prevSelected, brand];
      return newSelection.length === 0 ? [brands[0]] : newSelection;
    });
  };

  const theme = useTheme();
  const colors = [
    theme.palette.brands.A,
    theme.palette.brands.B,
    theme.palette.brands.C,
    theme.palette.brands.D,
    theme.palette.brands.E,
    theme.palette.brands.F,
    theme.palette.brands.G,
  ];
  const llmColors = [
    theme.palette.llms.A,
    theme.palette.llms.C,
    theme.palette.llms.B,
  ];
  const hoverColors = [
    theme.palette.brands.hoverA,
    theme.palette.brands.hoverB,
    theme.palette.brands.hoverC,
    theme.palette.brands.hoverD,
    theme.palette.brands.hoverE,
    theme.palette.brands.hoverF,
    theme.palette.brands.hoverG,
  ];
  const llmHoverColors = [
    theme.palette.llms.hoverA,
    theme.palette.llms.hoverC,
    theme.palette.llms.hoverB,
  ];

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        justifyContent: isLLM && "center",
        flexWrap: "wrap",
      }}
    >
      {brands.map((brand, index) => (
        <>
          <Typography
            key={brand}
            onClick={() => handleBrandClick(brand)}
            sx={{
              border: `1px solid ${
                selectedBrands?.includes(brand)
                  ? isLLM
                    ? llmColors[index]
                    : Object.keys(colorMap).length > 0
                    ? colorMap[brand]
                    : colors[index]
                  : theme.palette.border.secondary
              }`,
              padding: "10px 12px",
              fontSize: 14,
              borderRadius: "11px",
              cursor:
                brand === brands[0] && selectedBrands.length === 1
                  ? "default"
                  : "pointer",
              backgroundColor: selectedBrands?.includes(brand)
                ? isLLM
                  ? llmColors[index]
                  : Object.keys(colorMap).length > 0
                  ? colorMap[brand]
                  : colors[index]
                : "transparent",
              display: "flex",
              flexDirection: "row",
              columnGap: 0.5,
              alignItems: "center",
              color: selectedBrands?.includes(brand)
                ? theme.palette.text.chiptext
                : theme.palette.text.primary,
              "&:hover": {
                backgroundColor: !selectedBrands?.includes(brand)
                  ? isLLM
                    ? llmHoverColors[index]
                    : hoverColors[index]
                  : undefined,
                borderColor: !selectedBrands?.includes(brand)
                  ? isLLM
                    ? llmColors[index]
                    : colors[index]
                  : undefined,
              },
            }}
          >
            {isLLM && (
              <img
                src={LLMs[brand]}
                style={{ width: 14, height: 14 }}
                alt={brand}
              />
            )}
            {brand}
            {selectedBrands.includes(brand) && (
              <CloseIcon
                sx={{
                  fontSize: 16,
                  cursor: "pointer",
                  color: theme.palette.text.chiptext,
                }}
              />
            )}
          </Typography>
        </>
      ))}
    </Box>
  );
};

export default BrandsList;
