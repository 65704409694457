import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import { useTheme } from "@mui/material";
import CardWithIcon from "../../../../CommonComponents/CardWithIcon/CardWithIcon";
import ComparisionTable from "../ComparisonTable/ComparisionTable";

const EarnedComparision = ({ scores, brands }) => {
  const theme = useTheme();

  return (
    <CardWithIcon
      title={`Comparison Assessment Table (Earned Sources)`}
      height={"100%"}
      tooltipMessagePrimary="Earned sources refer to platforms which are not in brand's control, such as a 3rd party publisher like Forbes, TechCrunch, or Business Insider etc."
      tooltipMessageSecondary="This table evaluates your brand's and competitors' earned sources across key criteria that influence AI visibility and discoverability."
      icon={<LayersOutlinedIcon color={theme.palette.text.primary} />}
    >
      <ComparisionTable scores={scores} brands={brands} />
    </CardWithIcon>
  );
};
export default EarnedComparision;
