import React, { useState, useEffect, useRef } from "react";
import { Select, MenuItem, FormControl, useTheme } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";

const SingleCountryDropdown = ({ backgroundColorValue, onChange }) => {
  const theme = useTheme();
  const BgColor = theme.palette.background[backgroundColorValue];
  const { brandId } = useParams();
  const { initialCountries } = useGlobalFilters();

  const [selectedOption, setSelectedOption] = useState(initialCountries[0]);
  const isFirstLoad = useRef(true);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    onChange(value);
    if (onChange) onChange(value);
  };

  return (
    <FormControl>
      <Select
        value={selectedOption}
        onChange={handleChange}
        size="small"
        variant="standard"
        disableUnderline
        IconComponent={ExpandMoreOutlinedIcon}
        sx={{
          "& .MuiSelect-select": {
            borderRadius: "11px",
            padding: "4px 8px",
            backgroundColor: BgColor,
            fontSize: "14px",
            color: "text.tertiary",
            display: "flex",
            flexDirection: "row",
            columnGap: 0.5,
            alignItems: "center",
            fontWeight: 400,
          },
          "& .MuiSvgIcon-root": {
            fontSize: 15,
            color: "text.tertiary",
            marginRight: "5px",
            borderRadius: "11px",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 350,
              overflowY: "auto",
              backgroundColor: "background.secondary",
              borderRadius: "10px",
              boxShadow: "none",
              "& .MuiMenuItem-root": {
                color: "text.primary",
                "&:hover": {
                  backgroundColor: "dropdown.hover",
                },
                "&.Mui-selected": {
                  backgroundColor: "dropdown.selected",
                },
              },
            },
          },
        }}
      >
        {initialCountries.map((country) => (
          <MenuItem
            key={country}
            value={country}
            sx={{ fontSize: 14, color: "#000" }}
          >
            {country}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleCountryDropdown;
