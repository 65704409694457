import { useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import SourceTrackingTable from "../../../../components/SourceTrackingTable";

const SourceTrackingCard = () => {
  const {
    date: globalDate,
    initialDate: globalInitialDate,
    llm,
    noData,
  } = useGlobalFilters();
  const theme = useTheme();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const { brandId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const [data, setData] = useState([]);

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  return (
    <ChartCard
      title="Source Tracking"
      tooltipMessagePrimary="Shows your brand’s visibility score across various regions and selected LLM. Higher scores indicate stronger brand presence in each country or region."
      tooltipMessageSecondary="All score out of 100."
      description="Analyze and improve source performance through topic and prompt tracking in real time."
      height={'100%'}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      isLoading={loading}
      isError={error}
      hasCalendarSelect
      setDate={setDate}
      date={date}
      initialDate={initialDate}
    >
      <SourceTrackingTable />
    </ChartCard>
  );
};
export default SourceTrackingCard;
