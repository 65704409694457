import { Grid, Typography } from "@mui/material";

const ScoreGrid = ({ color, label, value }) => {
  return (
    <Grid container spacing={3} >
      <Grid item xs={8} sm={8} sx={{ display: "flex", alignItems: "center" ,flexDirection:'row'}}>
        <div
          style={{
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: color,
            marginRight: "8px",
          }}
        ></div>
        <Typography style={{ fontWeight: 500, fontSize: 14 }}>
          {label}
        </Typography>{" "}
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ fontWeight: 500, fontSize: 14 }}>
          {value}%
        </Typography>{" "}
      </Grid>
    </Grid>
  );
};
export default ScoreGrid;
