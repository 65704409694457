import CardWithIcon from "../../../../CommonComponents/CardWithIcon/CardWithIcon";

import WebView from "./components/WebView/WebView";
import { Box } from "@mui/material";
import MobView from "./components/MobView/MobView";
import { LLMs } from "../../../AEO/utils/DataUtils";

const gridValues = [
  { label: "recognized", iconType: "check", placement: "flex-end" },
  { label: "sentiment", iconType: "face", placement: "center" },
  { label: "brand_authority_score", iconType: "", placement: "flex-end" },
];

const EntityRecognitionScore = ({ selectedLLM, data }) => {
  const scores = data?.entity_recognition;
  const brand_authority_score = data?.brand_authority_score;
  return (
    data && (
      <CardWithIcon
        title="Entity Recognition and Authority Score"
        height={"100%"}
        tooltipMessagePrimary="Shows whether the brand is recognized as an entity by the LLM, the sentiment associated with its presence (positive, neutral, or negative), and its authority score (0-100), reflecting the brand’s influence and credibility."
      >
        <Box sx={{ display: { xs: "none", sm: "contents" } }}>
          <WebView
            selectedLLM={selectedLLM}
            scores={scores}
            brand_authority_score={brand_authority_score}
            LLMs={LLMs}
            gridValues={gridValues}
          />
        </Box>
        <Box sx={{ display: { xs: "contents", sm: "none" } }}>
          <MobView
            selectedLLM={selectedLLM}
            scores={scores}
            brand_authority_score={brand_authority_score}
            LLMs={LLMs}
            gridValues={gridValues}
          />
        </Box>
      </CardWithIcon>
    )
  );
};
export default EntityRecognitionScore;
