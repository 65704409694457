import { useCallback, useMemo, useState } from "react";
import StepperModal from "../../../../CommonComponents/StepperModal";
import Topic from "../../../BlogWriter/components/Topic";
import References from "../../../BlogWriter/components/References";
import Configuration from "../../../BlogWriter/components/Configuration";
import Outline from "../../../BlogWriter/components/Outline";
import Titles from "../../../BlogWriter/components/Titles";
import * as Yup from "yup";

const generateBlogSteps = [
  "Topic",
  "References",
  "Configure",
  "Select a Title",
  "Outline",
];

const topicValidationSchema = Yup.object().shape({
  topic: Yup.string().required("Required"),
});
const referencesValidationSchema = Yup.object().shape({
  references: Yup.array()
    .of(Yup.string().required("Required"))
    .required("Required")
    .min(1, "Select at least 1")
    .max(4, "You can select up to 4 options"),
});

const configurationValidationSchema = Yup.object({
  config: Yup.object({
    wordCount: Yup.string().required("Required"),
    primaryKeyword: Yup.string().required("Required"),
    secondaryKeyword: Yup.string().required("Required"),
  }),
});
const titleValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
});

// Function to get the validation schema based on the active step
const getValidationSchema = (activeStep) => {
  switch (activeStep) {
    case 0:
      return topicValidationSchema;
    case 1:
      return referencesValidationSchema;
    case 2:
      return configurationValidationSchema;
    case 3:
      return titleValidationSchema;
    default:
      return Yup.object();
  }
};
const GenerateBlogModal = ({ openBlogDrawer, setOpenBlogDrawer, brandId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [recommendedReferences, setRecommendedReferences] = useState([]);
  const [generatedOutline, setGeneratedOutline] = useState("");
  const [generatedTitles, setGeneratedTitles] = useState([]);
  const [topicValue, setTopicValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [generateBlogStepNextFn, setGenerateBlogStepNextFn] = useState();
  const [stepLoading, setStepLoading] = useState();
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [loadingAnimationText, setLoadingAnimationText] = useState(false);
  const [footerErrorMessage, setFooterErrorMessage] = useState("");
  const [brandDetails, setBrandDetails] = useState({});
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  const handleGeneratedBlogNext = useCallback(
    (step, setNextStep) => {
      if (generateBlogStepNextFn) {
        generateBlogStepNextFn(step, setNextStep);
      }
    },
    [generateBlogStepNextFn]
  );

  const nextFunction = useCallback((fn) => {
    setGenerateBlogStepNextFn(() => fn);
  }, []);
  const getGenerateBlogStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return (
            <Topic
              setReferences={setRecommendedReferences}
              setLoading={setStepLoading}
              nextFunction={nextFunction}
              setBrandDetails={setBrandDetails}
              setLoadingAnimation={setLoadingAnimation}
              setLoadingAnimationText={setLoadingAnimationText}
              setTopicValue={setTopicValue}
            />
          );
        case 1:
          return (
            <References
              setActiveStep={setActiveStep}
              references={recommendedReferences}
              nextFunction={nextFunction}
              setLoading={setStepLoading}
              setFooterErrorMessage={setFooterErrorMessage}
            />
          );
        case 2:
          return (
            <Configuration
              nextFunction={nextFunction}
              setGeneratedTitles={setGeneratedTitles}
              setLoadingAnimation={setLoadingAnimation}
              setLoadingAnimationText={setLoadingAnimationText}
            />
          );
        case 3:
          return (
            <Titles
              generatedTitles={generatedTitles}
              setGeneratedOutline={setGeneratedOutline}
              nextFunction={nextFunction}
              setLoadingAnimation={setLoadingAnimation}
              setLoadingAnimationText={setLoadingAnimationText}
              setTitleValue={setTitleValue}
            />
          );
        case 4:
          return (
            <Outline
              setActiveStep={setActiveStep}
              generatedOutline={generatedOutline}
              setLoadingAnimation={setLoadingAnimation}
              nextFunction={nextFunction}
              setOpenBlogDrawer={setOpenBlogDrawer}
              setLoadingAnimationText={setLoadingAnimationText}
            />
          );
        default:
          throw new Error("Unknown step");
      }
    },
    [
      recommendedReferences,
      generatedOutline,
      generatedTitles,
      nextFunction,
      setOpenBlogDrawer,
    ]
  );
  const generateBlogInitialValues = useMemo(
    () => ({
      userId: loggedInUser?.id,
      brandId: brandId,
      references: [],
      config: {
        languageStyle: brandDetails?.formality_tone,
        tone: brandDetails?.emotions,
        style: brandDetails?.styles,
        wordCount: "1500-2200",
      },
      generate_citation_flag: false,
      generate_FAQs_flag: false,
    }),
    [brandId, brandDetails, loggedInUser]
  );

  return (
    <StepperModal
      open={openBlogDrawer}
      handleClose={() => setOpenBlogDrawer(false)}
      setOpenModal={setOpenBlogDrawer}
      modalName="Blog Writer"
      title={titleValue || topicValue || "Your blog topic"}
      steps={generateBlogSteps}
      getStepContent={getGenerateBlogStepContent}
      formikChildren={(Topic, References, Configuration, Titles, Outline)}
      initialValues={generateBlogInitialValues}
      getValidationSchema={getValidationSchema}
      customNext
      handleCustomNext={handleGeneratedBlogNext}
      initialStep={activeStep}
      loading={stepLoading}
      selectedNumberName="references"
      selectedNumberStep={1}
      loadingAnimation={loadingAnimation}
      loadingAnimationText={loadingAnimationText}
      setLoadingAnimation={setLoadingAnimation}
      footerErrorMessage={footerErrorMessage}
      setFooterErrorMessage={setFooterErrorMessage}
      hasAutoSave={false}
    />
  );
};
export default GenerateBlogModal;
