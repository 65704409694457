import { Box, Typography, useTheme } from "@mui/material";

const Section = ({ details }) => {
  const theme = useTheme();
  return (
    <Box sx={{ maxWidth: "400px" }}>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 500,
          color: theme.palette.text.primary,
          marginBottom: 3,
        }}
      >
        {details.title}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
        {details?.recommendations?.map(({ label, details }, index) => (
          <Box
            sx={{ display: "flex", flexDirection: "row", columnGap: 2 }}
            key={index}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                borderRadius: "50%",
                border: "1px solid #404040",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: theme.palette.text.secondary,
                  padding: 6,
                }}
              >
                {index + 1}
              </Typography>
            </Box>

            <div>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                {label}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: theme.palette.text.tertiary,
                }}
              >
                {details}
              </Typography>
            </div>
          </Box>
        ))}{" "}
      </Box>
    </Box>
  );
};
export default Section;
