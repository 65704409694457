import React from "react";

const OpenLinkIcon = ({ color = "#141414" }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5.41C8.86739 5.41 8.74021 5.46268 8.64645 5.55645C8.55268 5.65021 8.5 5.77739 8.5 5.91V9.5C8.5 9.63261 8.44732 9.75979 8.35355 9.85355C8.25979 9.94732 8.13261 10 8 10H2.5C2.36739 10 2.24021 9.94732 2.14645 9.85355C2.05268 9.75979 2 9.63261 2 9.5V4C2 3.86739 2.05268 3.74021 2.14645 3.64645C2.24021 3.55268 2.36739 3.5 2.5 3.5H6.09C6.22261 3.5 6.34979 3.44732 6.44355 3.35355C6.53732 3.25979 6.59 3.13261 6.59 3C6.59 2.86739 6.53732 2.74021 6.44355 2.64645C6.34979 2.55268 6.22261 2.5 6.09 2.5H2.5C2.10218 2.5 1.72064 2.65804 1.43934 2.93934C1.15804 3.22064 1 3.60218 1 4V9.5C1 9.89782 1.15804 10.2794 1.43934 10.5607C1.72064 10.842 2.10218 11 2.5 11H8C8.39782 11 8.77936 10.842 9.06066 10.5607C9.34196 10.2794 9.5 9.89782 9.5 9.5V5.91C9.5 5.77739 9.44732 5.65021 9.35355 5.55645C9.25979 5.46268 9.13261 5.41 9 5.41ZM10.96 1.31C10.9093 1.18783 10.8122 1.09074 10.69 1.04C10.6299 1.01438 10.5653 1.00079 10.5 1H7.5C7.36739 1 7.24021 1.05268 7.14645 1.14645C7.05268 1.24021 7 1.36739 7 1.5C7 1.63261 7.05268 1.75979 7.14645 1.85355C7.24021 1.94732 7.36739 2 7.5 2H9.295L4.145 7.145C4.09814 7.19148 4.06094 7.24678 4.03555 7.30771C4.01017 7.36864 3.9971 7.43399 3.9971 7.5C3.9971 7.56601 4.01017 7.63136 4.03555 7.69229C4.06094 7.75322 4.09814 7.80852 4.145 7.855C4.19148 7.90186 4.24678 7.93906 4.30771 7.96445C4.36864 7.98983 4.43399 8.0029 4.5 8.0029C4.56601 8.0029 4.63136 7.98983 4.69229 7.96445C4.75322 7.93906 4.80852 7.90186 4.855 7.855L10 2.705V4.5C10 4.63261 10.0527 4.75979 10.1464 4.85355C10.2402 4.94732 10.3674 5 10.5 5C10.6326 5 10.7598 4.94732 10.8536 4.85355C10.9473 4.75979 11 4.63261 11 4.5V1.5C10.9992 1.43466 10.9856 1.37011 10.96 1.31Z"
      fill={color}
    />
  </svg>
);

export default OpenLinkIcon;
