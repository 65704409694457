import { CircularProgress, Typography } from "@mui/material";
import PageTemplate from "../../CommonComponents/PageTemplate/PageTemplate";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../api";
import axios from "axios";
import IncompleteDna from "./components/IncompleteDna/IncompleteDna";
import RecentSection from "./components/RecentSection/RecentSection";
import GenerateBlogModal from "./components/GenerateBlogModal";
import StepperModal from "../../CommonComponents/StepperModal";
import { getValidationSchema, steps } from "../BrandDna/conts";
import BrandVoice from "../BrandDna/components/BrandDNAForm/components/BrandVoice/BrandVoice";
import Competition from "../BrandDna/components/BrandDNAForm/components/Competition/Competition";
import Business from "../BrandDna/components/BrandDNAForm/components/Business";
import Persona from "../BrandDna/components/BrandDNAForm/components/Persona";
import Global from "../BrandDna/components/BrandDNAForm/components/Global";

import { GlobalFiltersProvider } from "../../contexts/GlobalFiltersContext";
import AEOCharts from "./components/AEOCharts/AEOCharts";

const Dashboard = () => {
  const { brandId: id } = useParams();
  const brandId = id || localStorage.getItem("brandId");
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);
  const [isDnaCompleted, setIsDnaCompleted] = useState(false);
  const [openBlogDrawer, setOpenBlogDrawer] = useState(false);
  const [openDNAModal, setOpenDNAModal] = useState(false);
  const userBrands = JSON.parse(localStorage.getItem("loggedInUser"))?.brands;
  const [isAutoSave, setIsAutoSave] = useState(false);
  const [brandDetails, setBrandDetails] = useState({});
  const [featureFlags, setFeatureFlags] = useState();

  const companyName =
    localStorage.getItem("worskapceName") ||
    userBrands?.filter((brand) => parseInt(brand.id) === parseInt(brandId))[0]
      ?.name;

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}/overview`)
      .then((response) => {
        const workspaceData = response.data;
        const feature_flags = workspaceData.feature_flags;

        localStorage.setItem("workspace", JSON.stringify(workspaceData));
        const iscomplete = workspaceData.is_brand_dna_completed;
        setBrandDetails(workspaceData);
        setIsDnaCompleted(iscomplete);

        localStorage.setItem("feature_flags", JSON.stringify(feature_flags));
        setFeatureFlags(feature_flags);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
        setLoading(false);
      });
  }, [brandId]);

  const initialValues = useMemo(
    () => ({
      // business
      name: brandDetails?.name,
      primary_product: brandDetails?.primary_product,
      description: brandDetails?.description,
      conversion_goals: brandDetails?.conversion_goals || [],
      industry: brandDetails?.industry || "",
      business_model: brandDetails?.business_model || "",
      features: brandDetails?.features || ["", ""],
      benefits: brandDetails?.benefits || ["", ""],
      market_focus: brandDetails?.market_focus || "",
      usp: brandDetails.usp || "",
      country: brandDetails?.country || "",
      primary_language: brandDetails.primary_language || "",
      website: brandDetails?.website || "",
      additional_websites: brandDetails?.additional_websites || [""],
      secondary_countries: brandDetails?.secondary_countries || [],
      topics: brandDetails?.topics || [""],
      market_attributes: brandDetails?.market_attributes || [""],
      comparison_attributes: brandDetails?.comparison_attributes || [""],
      social_media_accounts_urls: brandDetails?.social_media_accounts_urls || [
        "",
      ],

      // persona
      age_ranges: brandDetails?.age_ranges || [],
      genders: brandDetails?.genders || [],
      interests: brandDetails?.interests || [],
      job_functions: brandDetails?.job_functions || [],
      job_titles: brandDetails?.job_titles || [],
      desires: brandDetails?.desires || ["", ""],
      pain_points: brandDetails?.pain_points || ["", ""],

      // brand voice
      brand_voice: brandDetails?.brand_voice || "",
      not_brand_voice: brandDetails?.not_brand_voice || "",
      formality_tone: brandDetails?.formality_tone || "",
      core_themes: brandDetails?.core_themes || [],
      emotions: brandDetails?.emotions || [],
      styles: brandDetails?.styles || [],

      // competitors
      competitors: brandDetails?.competitors || [{ name: "", website: "" }],
    }),
    // eslint-disable-next-line
    [brandId, brandDetails]
  );
  const handleSave = useCallback(
    (values) => {
      !isAutoSave && setLoading(true);
      axios
        .patch(`${API_URL}/brands/${brandId}`, values)
        .then((response) => {
          if (!isAutoSave) {
            setLoading(false);
            window.location.href = `/dashboard/${brandId}/`;
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [brandId, isAutoSave]
  );

  const getStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return <Business brandDetails={{}} />;
      case 1:
        return <Persona brandDetails={{}} />;

      case 2:
        return <BrandVoice brandDetails={{}} />;
      case 3:
        return <Competition brandDetails={{}} />;
      case 4:
        return <Global brandDetails={{}} />;
      default:
        return <Business brandDetails={{}} />;
    }
  }, []);

  useEffect(() => {
    if (!isDnaCompleted && !loading) setOpenDNAModal(true);
    else setOpenDNAModal(false);
  }, [isDnaCompleted, loading]);

  return loading ? (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "60%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        style={{
          width: 20,
          height: 20,
          color: "#2D333A",
        }}
      />
    </div>
  ) : (
    <PageTemplate>
      <Typography
        style={{
          fontSize: "37.01px",
          fontWeight: 500,
          margin: 0,
          padding: 0,
          lineHeight: 1,
        }}
        color="text.primary"
      >
        Hey, I'm Goodie.
      </Typography>
      <Typography
        style={{
          fontSize: "37.01px",
          fontWeight: 500,
          marginBottom: 50,
          margin: 0,
          padding: 0,
        }}
        color="text.tertiary"
      >
        Your supercharged, AI-powered marketing partner.{" "}
      </Typography>

      {!isDnaCompleted && <IncompleteDna />}
      {featureFlags?.can_access_aeo && (
        <GlobalFiltersProvider>
          <AEOCharts />
        </GlobalFiltersProvider>
      )}
      {featureFlags?.can_access_content_writer && <RecentSection />}
      <GenerateBlogModal
        openBlogDrawer={openBlogDrawer}
        setOpenBlogDrawer={setOpenBlogDrawer}
        brandId={brandId}
      />
      <StepperModal
        open={openDNAModal}
        handleClose={() => setOpenDNAModal(false)}
        setOpenModal={setOpenDNAModal}
        modalName="Brand DNA"
        title={companyName}
        steps={steps}
        initialStep={0}
        getStepContent={getStepContent}
        formikChildren={(Business, Persona, BrandVoice, Competition)}
        initialValues={initialValues}
        getValidationSchema={getValidationSchema}
        handleSave={handleSave}
        loading={loading}
        setIsAutoSave={setIsAutoSave}
        isAutoSave={isAutoSave}
      />
    </PageTemplate>
  );
};
export default Dashboard;
