import { Box, Typography, useTheme } from "@mui/material";
import LineChart from "../../../../../../CommonComponents/Charts/LineChart";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import { dummyLineData } from "../../../../consts/consts";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";

const OverallLineChartCard = ({
  data,
  mainArea = "score",
  isIncrease,
  index,
}) => {
  const theme = useTheme();
  const { Score, Movement } = data;
  const { noData } = useGlobalFilters();
  let mainAreaColor =
    isIncrease || noData
      ? theme.palette.text.positive
      : theme.palette.text.negative;
  let backgroundColor =
    isIncrease || noData
      ? theme.palette.background.positive
      : theme.palette.background.negative;

  return (
    <Box style={{ display: "flex", flexDirection: "column", rowGap: 0 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 10,
          alignItems: "baseline",
        }}
      >
        <Typography style={{ fontSize: 30, fontWeight: 450 }}>
          {noData ? "1.8" : Score && Math.round(Score)}
        </Typography>
        <Box
          style={{
            color: mainAreaColor,
            backgroundColor: backgroundColor,
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            columnGap: 2,
            fontSize: 14,
            padding: 2,
          }}
        >
          {isIncrease ? (
            <TrendingUpOutlinedIcon />
          ) : (
            <TrendingDownOutlinedIcon />
          )}
          {noData ? "1.8" : Movement && Math.round(Movement)}%
        </Box>
      </div>

      <LineChart
        data={noData ? dummyLineData : data?.Line_Chart}
        mainArea={noData ? "NoGood" : mainArea}
        mainAreaColor={mainAreaColor}
        index={index}
        height={100}
        isMainSelected
      />
      <Typography
        style={{ fontSize: 12, fontWeight: 400, marginTop: 6 }}
        color="text.tertiary"
      >
        Displaying data from all time
      </Typography>
    </Box>
  );
};
export default OverallLineChartCard;
