import React from "react";
import { PieChart, Pie, Cell, Customized, ResponsiveContainer } from "recharts";

const MultipleValuesPercentageWheel = ({
  wheelColors,
  data,
  wheelCenterText,
  width = 135,
  height = 135,
  wheelInnerRaduis = 57,
  wheelOuterRaduis = 67,
}) => {
  const COLORS = wheelColors;

  const renderCenterText = () => {
    return (
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="28"
        fill="#66C285"
      >
        {wheelCenterText}%
      </text>
    );
  };
  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={wheelInnerRaduis}
          outerRadius={wheelOuterRaduis}
          paddingAngle={5}
          labelLine={false}
          cornerRadius={15}
        >
          {data?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index]}
              stroke={COLORS[index]}
              strokeWidth={2}
              style={{ borderRadius: 15 }}
            />
          ))}
        </Pie>
        {wheelCenterText && <Customized component={renderCenterText} />}{" "}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MultipleValuesPercentageWheel;
