import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const PromptCounter = ({ prompt, tooltipMessage, title, loading }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: "3px 10px",
        borderRadius: "11px",
        backgroundColor: theme.palette.background.secondary,
        display: "flex",
        felxDirection: "row",
        columnGap: 1,
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: theme.palette.text.tertiary,
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 0.5,
        }}
      >
        {title}:{"  "}
        {loading ? (
          <CircularProgress
            style={{
              width: 20,
              height: 20,
              color: "#2D333A",
            }}
          />
        ) : (
          <span
            style={{
              color: theme.palette.text.primary,
            }}
          >
            {prompt?.prompt_index || prompt?.score}
          </span>
        )}
      </Typography>

      {!loading && (
        <Typography
          sx={{
            color: theme.palette.text.positive,
            backgroundColor: theme.palette.background.positive,
            padding: "0px 4px",
            borderRadius: "7px",
            fontSize: "14px",
          }}
        >
          + {prompt?.movement}
        </Typography>
      )}
      <Tooltip
        title={<div style={{ whiteSpace: "pre-wrap" }}>{tooltipMessage}</div>}
      >
        <InfoOutlinedIcon
          sx={{ color: "text.tertiary", width: 18, height: 18 }}
        />
      </Tooltip>
    </Box>
  );
};
export default PromptCounter;
