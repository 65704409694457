import { useTheme } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../../../api";
import StarIcon from "../../../../../../CommonComponents/customIcons/StarIcon";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import ScoreMovementTypography from "../ScoreMovementTypography";

const ARICard = ({}) => {
  const { date: globalDate, initialDate: globalInitialDate } =
    useGlobalFilters();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const { brandId } = useParams();

  useEffect(() => {
    if (date.length > 0) {
      const values = {
        brandId,
        start_date: date[0] && date[0].format("YYYY-MM-DD"),
        end_date: date[1] && date[1].format("YYYY-MM-DD"),
      };
      setLoading(true);
      axios
        .post(`${ML_AEO_URL}/aeo/reputation_index`, values)
        .then((response) => {
          setLoading(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
    }
  }, [brandId, date]);
  return (
    <ChartCard
      icon={<StarIcon color={theme.palette.text.primary} />}
      title="AI Reputation Index (ARI)"
      tooltipMessagePrimary="A measure of brand visibility considering factors such as the number of sources, frequency of mentions, and page placement."
      description="A measure of brand visibility considering factors such as the number of sources, frequency of mentions, and page placement."
      height={168}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      hasCalendarSelect
      isLoading={loading}
      isError={error}
    >
      <ScoreMovementTypography
        score={data?.reputation_index?.toFixed(0)}
        movement={data?.movement?.toFixed(0)}
      />
    </ChartCard>
  );
};
export default ARICard;
