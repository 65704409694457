import { Grid } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import ARICard from "./components/ARICard/ARICard";
import AIAuthorityScoreCard from "./components/AIAuthorityScoreCard/AIAuthorityScoreCard";
import SourceBreakdownCard from "./components/SourceBreakdownCard";
import TopicVisbilityLineChart from "./components/TopicVisbilityLineChart";
import TopicRanking from "./components/TopicRanking";
import RiskAuditCard from "./components/RiskAuditCard/RiskAuditCard";
import SourceTrackingCard from "./components/SourceTrackingCard";

const OptimizationOverview = () => {
  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="AI Optimization Overview"
        description="Analyze and improve source performance through topic and prompt tracking in real time."
        isOverview
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={5.85}
            style={{ display: "flex", flexDirection: "column", rowGap: 20 }}
          >
            <ARICard />
            <AIAuthorityScoreCard />
          </Grid>
          <Grid item xs={5.85}>
            <SourceBreakdownCard />
          </Grid>
        </Grid>
        <TopicVisbilityLineChart />

        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {" "}
          <Grid item xs={3.8}>
            <TopicRanking />
          </Grid>
          <Grid item xs={7.9}>
            <RiskAuditCard />
          </Grid>
        </Grid>
        <SourceTrackingCard />
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default OptimizationOverview;
