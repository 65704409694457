import ChartCard from "../../../../components/ChartCard/ChartCard";
import { useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ML_AEO_URL } from "../../../../../../api";
import BarChart from "../../../../../../CommonComponents/Charts/BarChart";
import GlobalIcon from "../../../../../../CommonComponents/customIcons/GlobalIcon";
import { dummyRegionsData } from "../../../../consts/consts";

function flattenData(data) {
  return data.map((item) => ({
    label: item.label,
    value: Math.round(Object.values(item.scores)[0]) || null,
    fill: "#718CF9",
    backgroundValue: 100 - Math.round(Object.values(item.scores)[0]),
  }));
}

const VisibilityAcrossRegions = () => {
  const theme = useTheme();
  const { llm, noData, selectedCountries } = useGlobalFilters();
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const [data, setData] = useState(noData ? flattenData(dummyRegionsData) : []);
  const { brandId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  function flattenData(data) {
    return data.map((item) => ({
      label: item.label,
      value: Object.values(item.scores)[0].toFixed(1) || null,
      fill: theme.palette.bars.purble,
      backgroundValue: 100 - Object.values(item.scores)[0].toFixed(1),
    }));
  }

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);
  useEffect(() => {
    const values = {
      brandId,
      countries: selectedCountries,
      LLMs: selectedLLM,
    };
    setLoading(true);
    axios
      .post(`${ML_AEO_URL}/aeo/global-visibility-regions`, values)
      .then((response) => {
        setLoading(false);

        const data = response.data;
        setData(flattenData(data));
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, [brandId, selectedLLM, selectedCountries]);

  return (
    <ChartCard
      icon={<GlobalIcon color={theme.palette.text.primary} />}
      title="Visibility Across Regions"
      tooltipMessagePrimary="Shows your brand’s visibility score across various regions and selected LLMs. Higher scores indicate a stronger brand presence in each country or region."
      tooltipMessageSecondary="Scores are out of 100."
      height={254}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
      isLoading={loading}
      isError={error}
    >
      <BarChart data={data} xAxisDataKey="label" width={200} height={190} />
    </ChartCard>
  );
};
export default VisibilityAcrossRegions;
