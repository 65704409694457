import { Box, useTheme } from "@mui/material";
import { getRiskColor } from "../../../../../AEO/utils/DataUtils";

const RiskTableCell = ({ value, piriority }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        backgroundColor: getRiskColor(piriority, theme)[0],
        color: getRiskColor(piriority, theme)[1],
        fontSize: 13,
        borderRadius: 7,
        fontWeight: 400,
        padding: "0px 5px",
        display: "inline-block",
        maxWidth: "fit-content",
      }}
    >
      {value}
    </Box>
  );
};
export default RiskTableCell;
