import React from "react";

const AuthorityIcon = ({ color = "#AFB1B7", width, height }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.086 2.43338C13.0086 2.37072 12.9181 2.32623 12.8212 2.30315C12.7242 2.28008 12.6234 2.27902 12.526 2.30004C11.8136 2.44934 11.0782 2.45124 10.3651 2.30563C9.65197 2.16002 8.97618 1.86999 8.37935 1.45338C8.26776 1.37596 8.13517 1.33447 7.99935 1.33447C7.86353 1.33447 7.73095 1.37596 7.61935 1.45338C7.02252 1.86999 6.34674 2.16002 5.6336 2.30563C4.92046 2.45124 4.18506 2.44934 3.47269 2.30004C3.37531 2.27902 3.27446 2.28008 3.17755 2.30315C3.08064 2.32623 2.99014 2.37072 2.91269 2.43338C2.83534 2.49612 2.77303 2.5754 2.73035 2.66539C2.68766 2.75537 2.66568 2.85378 2.66602 2.95338V7.92004C2.66543 8.87588 2.8932 9.81802 3.33038 10.668C3.76755 11.518 4.40147 12.2513 5.17935 12.8067L7.61269 14.54C7.72559 14.6204 7.86075 14.6636 7.99935 14.6636C8.13796 14.6636 8.27311 14.6204 8.38602 14.54L10.8194 12.8067C11.5972 12.2513 12.2312 11.518 12.6683 10.668C13.1055 9.81802 13.3333 8.87588 13.3327 7.92004V2.95338C13.333 2.85378 13.311 2.75537 13.2684 2.66539C13.2257 2.5754 13.1634 2.49612 13.086 2.43338ZM11.9994 7.92004C11.9999 8.66321 11.8229 9.39576 11.4831 10.0567C11.1434 10.7177 10.6507 11.2879 10.046 11.72L7.99935 13.18L5.95269 11.72C5.34804 11.2879 4.85534 10.7177 4.51559 10.0567C4.17583 9.39576 3.99884 8.66321 3.99935 7.92004V3.72004C5.39697 3.83966 6.79671 3.51539 7.99935 2.79338C9.20199 3.51539 10.6017 3.83966 11.9994 3.72004V7.92004Z"
      fill={color}
    />
  </svg>
);

export default AuthorityIcon;
