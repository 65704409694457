import { MenuItem, Select, useTheme } from "@mui/material";
import chatgptIcon from "../../../../assets/images/chatgpt.png";
import perplexityIcon from "../../../../assets/images/perplexity.png";
import geminiIcon from "../../../../assets/images/gemini.png";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
const LLMs = [
  { name: "ChatGpt", icon: chatgptIcon, label: "ChatGPT" },
  { name: "Perplexity", icon: perplexityIcon, label: "Perplexity" },
  { name: "Gemini", icon: geminiIcon, label: "Gemini" },
];
const ASSESSMENT_LLMS = [
  { name: "ChatGpt", icon: chatgptIcon, label: "ChatGPT" },
  { name: "Gemini", icon: geminiIcon, label: "Gemini" },
];

const LLMDropDown = ({
  isMultipleSelect,
  selectedLLM,
  setSelectedLLM,
  backgroundColorValue,
  assessment_llms,
}) => {
  const theme = useTheme();
  const BgColor = theme.palette.background[backgroundColorValue];
  const llms = assessment_llms ? ASSESSMENT_LLMS : LLMs;
  return (
    <Select
      variant="standard"
      disableUnderline
      value={selectedLLM}
      onChange={(e) =>
        setSelectedLLM(isMultipleSelect ? e.target.value : [e.target.value])
      }
      IconComponent={ExpandMoreOutlinedIcon}
      multiple={isMultipleSelect}
      sx={{
        "& .MuiSelect-select": {
          padding: "4px 8px",
          borderRadius: "11px",
          backgroundColor: BgColor,
          fontSize: "14px",
          color: "text.primary",
          display: "flex",
          flexDirection: "row",
          columnGap: 0.5,
          alignItems: "center",
          fontWeight: 400,
        },
        "& .MuiSvgIcon-root": {
          fontSize: 14,
          color: "text.primary",
          marginRight: "5px",
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: "background.secondary",
            borderRadius: "10px",
            boxShadow: "none",
            "& .MuiMenuItem-root": {
              color: "text.primary",
              "&:hover": {
                backgroundColor: "dropdown.hover",
              },
              "&.Mui-selected": {
                backgroundColor: "dropdown.selected",
              },
            },
          },
        },
      }}
    >
      {llms.map((model) => (
        <MenuItem
          value={model.name}
          sx={{
            fontSize: "14px",
            color: "#000",
            display: "flex",
            flexDirection: "row",
            columnGap: 0.5,
            alignItems: "center",
          }}
        >
          <img
            src={model.icon}
            style={{ width: 14, height: 14 }}
            alt={model.name}
          />{" "}
          {model.label}
        </MenuItem>
      ))}
    </Select>
  );
};
export default LLMDropDown;
