import { Box, Typography } from "@mui/material";
import ChipButton from "../../CommonComponents/ChipButton";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import ExclamationIcon from "../../CommonComponents/customIcons/ExclamationIcon";

const ExpiredAEOReport = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 70px",
        position: "relative",
        overflow: "hidden",
        background: " #1A1A1A",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          left: "10%",
          width: "50%",
          height: "15%",
          background: "#7D39EC",
          filter: "blur(435.71px)",
          opacity: 1,
          zIndex: 0,
        }}
      ></Box>
      <Box
        style={{
          backgroundColor: "#1A1A1A",
          borderRadius: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: "20px",
          padding: "50px 70px",
          zIndex: 1,
        }}
      >
        <ExclamationIcon />
        <Typography
          style={{
            fontSize: 36,
            fontWeight: 500,
            color: "#FFFFFF",
          }}
        >
          Your AI Performance Report has expired.{" "}
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: "#B2B2B2",
          }}
        >
          Reach out below to learn more about how Goodie can elevate your brand
          in the world of AI search.
        </Typography>
        <div>
          <ChipButton
            iconAfter={<EastIcon />}
            onClick={() =>
              (window.location.href = `https://www.higoodie.com/#signup`)
            }
            label="Get Started"
            backgroundColor="#C7F565"
            textColor="#141414"
            hoverBackgroundColor="#EBFFC1"
            hoverTextColor="#141414"
            hoverBorderColor="#E1E1E1"
            padding="5px 15px"
          />
        </div>
      </Box>
    </Box>
  );
};

export default ExpiredAEOReport;
