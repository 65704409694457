import { Grid, Typography } from "@mui/material";
import CriteriaScore from "../CriteriaScore/CriteriaScore";

const CriteriaRow = ({ criteria, values }) => {
  const gridValues = Object.values(values);

  return (
    <Grid
      container
      width="100%"
      sx={{
        justifyContent: { sm: "space-between" },
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={4}
        sm={5}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 5,
          alignItems: "center",
        }}
      >
        <Typography
          color="text.primary"
          style={{ fontWeight: 400, fontSize: 14 }}
        >
          {criteria}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6.5}
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 5,
        }}
      >
        {gridValues.map((value, index) => (
          <CriteriaScore score={value} />
        ))}
      </Grid>
    </Grid>
  );
};
export default CriteriaRow;
