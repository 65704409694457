import { Box, List, Typography, useTheme } from "@mui/material";
import CustomTooltip from "../CustomTooltip";
import SidebarButton from "../SideBarButton/SidebarButton";

const CollapsedTooltip = ({
  isCollapsed,
  title,
  children,
  icon,
  isDisabled,
}) => {
  const theme = useTheme();
  return (
    <CustomTooltip
      title={
        isCollapsed ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "background.secondary",
            }}
          >
            <List
              component="div"
              disablePadding
              sx={{
                gap: isCollapsed ? "12px" : "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  margin: 0,
                  padding: "0px 0px 0px 12px",
                  lineHeight: "18px",
                }}
                color="text.secondary"
              >
                {title}
              </Typography>
              {children}
            </List>
          </Box>
        ) : (
          ""
        )
      }
      placement="right"
      disableHoverListener={!isCollapsed}
      tooltipMargin="0px 0px 0px 20px !important"
      tooltipPadding="10px 0px"
      bgColor={theme.palette.background.secondary}
    >
      {isCollapsed && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SidebarButton
            IconComponent={icon}
            isCollapsed={isCollapsed}
            collapsedPadding="5px"
            expandedPadding="8px"
            isDisabled={isDisabled}
          />
        </Box>
      )}
    </CustomTooltip>
  );
};
export default CollapsedTooltip;
