import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import ChipButton from "../../../../CommonComponents/ChipButton";
import Section from "./components/section";
import EastIcon from "@mui/icons-material/East";
import { useEffect, useState } from "react";

const Recommendations = ({ data }) => {
  const theme = useTheme();
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    setRecommendations(data?.recommendations?.recommendations || []);
  }, [data]);
  return (
    <Box
      style={{
        borderRadius: 10,
        padding: 25,
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        width: "100%",
        height: "100%",
        position: "relative",
        background: "linear-gradient(to bottom,  #262626 30%,#3C3079)",
      }}
    >
      <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
        Optimization Recommendations:{" "}
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        divider={
          recommendations?.length > 1 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: "#404040",
                borderWidth: "0.3px",
                margin: { xs: "26px 0px 22px 0px", sm: "0px 20px 0px 40px" },
              }}
            />
          )
        }
        spacing={0}
        width="100%"
        height="100%"
      >
        {recommendations?.map((recommendation, index) => (
          <Section key={index} details={recommendation} />
        ))}
      </Stack>

      <Box
        sx={{
          backgroundColor: "#8DA3FA33",
          padding: "20px",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          justifyContent: "space-between",
          borderRadius: "10px",
          marginTop: 2,
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#C6D1FD",
            maxWidth: "541px",
          }}
        >
          Ready to take the guesswork out of AI search optimization? Goodie
          offers an advanced suite of tools to turn these recommendations into
          real-world results.
        </Typography>
        <ChipButton
          iconAfter={<EastIcon />}
          label="Get Started"
          backgroundColor="#C7F565"
          textColor={theme.palette.text.chiptext}
          hoverBackgroundColor="#EBFFC1"
          hoverTextColor={theme.palette.text.chiptext}
          hoverBorderColor={theme.palette.text.brandbold}
          onClick={() =>
            (window.location.href = `https://www.higoodie.com/#signup`)
          }
        />
      </Box>
    </Box>
  );
};
export default Recommendations;
