import {
  CircularProgress,
  Grid,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button, Box } from "@mui/material";
import { Formik, Form } from "formik";
import CustomStepper from "../Stepper/Stepper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ChipButton from "../ChipButton/ChipButton";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import AnimationLoader from "../AnimationLoader/AnimationLoader";
import ErrorTypography from "../FormField/components/ErrorMessage/components/ErrorTypography/ErrorTypography";
import { AutoSave } from "./AutoSave/AutoSave";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const StepperModal = ({
  open,
  handleClose,
  modalName,
  title,
  setOpenModal,
  steps,
  handleSave,
  initialValues,
  getStepContent,
  formikChildren,
  initialStep = 0,
  getValidationSchema,
  customNext,
  handleCustomNext,
  loading,
  selectedNumberName,
  selectedNumberStep,
  loadingAnimation,
  loadingAnimationText,
  setLoadingAnimation,
  footerErrorMessage,
  setFooterErrorMessage,
  hasAutoSave = true,
  setIsAutoSave,
  isAutoSave,
  showSaveButton = false,
}) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const isLastStep = activeStep === steps.length - 1;
  const formikRef = useRef(null);
  const [saveState, setSaveState] = useState("");

  useEffect(() => {
    setActiveStep(initialStep);
  }, [initialStep]);

  const handleSubmit = (values) => {
    setSaveState("Saving...");
    handleSave(values);
    setTimeout(() => {
      setSaveState("Changes Saved");
    }, 1000);
  };

  const handleNext = (values) => {
    if (isLastStep && !customNext) {
      hasAutoSave && setIsAutoSave(false);
      handleSubmit(values);
    } else {
      customNext
        ? handleCustomNext(activeStep, setActiveStep)
        : setActiveStep((prevStep) => prevStep + 1);
    }
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }, [activeStep]);

  useEffect(() => {
    !open && resetModal();
  }, [open]);

  const resetModal = () => {
    loadingAnimation && setLoadingAnimation(false);
    setActiveStep(initialStep);
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setOpenModal(false);
  };
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "92%",
    bgcolor: "background.secondary",
    borderRadius: "35px",
    boxShadow: 24,
    height: "80%",
    outline: "none",
  };
  return (
    <Modal open={open} onClose={resetModal}>
      <Box sx={style}>
        <Grid container sx={{ height: "100%", borderRadius: "35px" }}>
          <Grid
            item
            xs={2.8}
            style={{
              backgroundColor: theme.palette.background.primary,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              rowGap: 100,
              borderTopLeftRadius: "35px",
              borderBottomLeftRadius: "35px",
              zIndex: 9999,
            }}
          >
            <Box sx={{ marginLeft: 5, marginTop: 4 }}>
              <Typography
                sx={{ fontSize: 14, fontWeight: 500 }}
                color="text.secondary"
              >
                {modalName}
              </Typography>
              <Typography
                sx={{
                  mt: "13px",
                  fontSize: 22,
                  fontWeight: 450,
                  width: "80%",
                  lineHeight: "29px",
                  letterSpacing: "-0.22px",
                }}
                color="text.primary"
              >
                {title}
              </Typography>
            </Box>

            <CustomStepper
              setActiveStep={setActiveStep}
              enableStepperClick
              activeStep={activeStep}
              steps={steps}
              hasClickableSteps={hasAutoSave}
            />
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: 2,
              cursor: "pointer",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => resetModal()}
                sx={{
                  color: "text.primary",
                  fontSize: "20px",
                }}
              >
                <CloseOutlinedIcon />
              </Button>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              children={formikChildren}
              validationSchema={getValidationSchema(activeStep)}
              innerRef={formikRef}
              enableReinitialize={true}
            >
              {({ values, isValid, dirty, errors }) => (
                <>
                  {hasAutoSave && (
                    <AutoSave
                      setIsAutoSave={setIsAutoSave}
                      isAutoSave={isAutoSave}
                      handleSave={handleSubmit}
                    />
                  )}
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflowY: "auto",
                      paddingRight: 2,
                      marginBottom: "100px",
                      position: "relative",
                    }}
                  >
                    <Form
                      style={{
                        height: "100%",
                        overflowY: "auto",
                        pointerEvents: loadingAnimation ? "none" : "all",
                        transition: "opacity 0.3s ease",
                      }}
                    >
                      {getStepContent(activeStep, values)}
                      {loadingAnimation && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: theme.palette.background.secondary,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1,
                          }}
                        >
                          <AnimationLoader text={loadingAnimationText} />
                        </div>
                      )}
                    </Form>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      backgroundColor: "background.secondary",
                      padding: 3,
                      borderBottomRightRadius: 35,
                      borderBottomLeftRadius: 35,
                      borderTop: `1px solid ${theme.palette.border.tertiary}`,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: 1,
                      }}
                    >
                      {footerErrorMessage && (
                        <ErrorTypography errorMessage={footerErrorMessage} />
                      )}
                      {values[selectedNumberName] &&
                        selectedNumberStep === activeStep && (
                          <Typography
                            style={{ fontSize: 14 }}
                            color="text.secondary"
                          >
                            {values[selectedNumberName].length} selected
                          </Typography>
                        )}
                      {dirty && showSaveButton && saveState && (
                        <Typography
                          sx={{
                            fontSize: 14,
                            color:
                              saveState === "Saving..."
                                ? "text.primary"
                                : "text.positive",
                            display: "flex",
                            alignItems: "center",
                            columnGap: 0.5,
                            backgroundColor:
                              saveState === "Saving..."
                                ? "background.brand20"
                                : "background.positive",
                            padding: "5px 8px",
                            borderRadius: "30px",
                          }}
                          disabled={!isValid || loading}
                        >
                          {saveState === "Changes Saved" && (
                            <CheckCircleOutlineIcon
                              sx={{
                                fontSize: 18,
                              }}
                            />
                          )}
                          {saveState}
                        </Typography>
                      )}
                      {activeStep > 0 && (
                        <ChipButton
                          onClick={() => {
                            footerErrorMessage && setFooterErrorMessage("");
                            setActiveStep((prevStep) => prevStep - 1);
                          }}
                          iconBefore={<WestIcon />}
                          label="Back"
                          backgroundColor="transparent"
                          textColor={theme.palette.text.primary}
                          borderColor={theme.palette.border.brand}
                          hoverBackgroundColor={
                            theme.palette.background.brandhover
                          }
                          hoverTextColor={theme.palette.text.chiptext}
                          hoverBorderColor={theme.palette.background.brandhover}
                          padding="5px 15px"
                          disabled={loading}
                        />
                      )}
                      <ChipButton
                        iconAfter={<EastIcon />}
                        label={
                          loading ? (
                            <CircularProgress
                              style={{
                                width: 20,
                                height: 20,
                                color: "#2D333A",
                              }}
                            />
                          ) : isLastStep ? (
                            "Finish"
                          ) : (
                            "Next"
                          )
                        }
                        backgroundColor={theme.palette.background.brand}
                        textColor={theme.palette.text.chiptext}
                        hoverBackgroundColor={
                          theme.palette.background.brandhover
                        }
                        hoverTextColor={theme.palette.text.chiptext}
                        hoverBorderColor={theme.palette.background.brandhover}
                        padding="5px 15px"
                        onClick={() => handleNext(values)}
                        disabled={
                          (!hasAutoSave &&
                            (!isValid || loading || loadingAnimation)) ||
                          (hasAutoSave && !isValid && isLastStep)
                        }
                      />
                    </Box>
                  </Box>{" "}
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default StepperModal;
