import { Grid, useTheme } from "@mui/material";
import PageLayout from "../../components/PageLayout/PageLayout";
import LineChartCard from "../../components/LineChartCard/LineChartCard";
import { GlobalFiltersProvider } from "../../../../contexts/GlobalFiltersContext";
import VisibilityAcrossLanguages from "./Components/VisibilityAcrossLanguages/VisibilityAcrossLanguages";
import VisibilityAcrossRegions from "./Components/VisibilityAcrossRegions";
import GlobalIcon from "../../../../CommonComponents/customIcons/GlobalIcon";

const GlobalMonitoring = () => {
  const theme = useTheme();

  return (
    <GlobalFiltersProvider>
      <PageLayout
        title="Global Monitoring"
        description="Access a worldwide view of brand visibility, performance, and sentiment with heatmaps and regional data. Drill down into localized insights to support your global strategy effectively."
        hasGlobalFilters
        hasCountriesSelect
        isGlobal
      >
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={5.85}>
            <VisibilityAcrossLanguages />
          </Grid>
          <Grid item xs={5.85}>
            <VisibilityAcrossRegions />
          </Grid>{" "}
        </Grid>

        <LineChartCard
          icon={<GlobalIcon color={theme.palette.text.primary} />}
          title="Performance by Region"
          tooltipPrimary="Track your brand’s performance score over time across selected competitors and LLMs within the specified region. Use the dropdown to select your preferred LLM."
          hasCaledarSelect
          hasLLMselect
          hasCountryDropdown
          height={'100%'}
          chartHasXasix
          chartHasYaxis
          chartHeight={200}
          chartHasTooltip
          chartHasMarginTop={20}
          chartHasMarginLeft={-20}
          chartHasGrid
          isGlobal
        />
        {/* 
      <LineChartCard
        icon={<VisibilityIcon color={theme.palette.text.primary} />}
        title="Performance by Language"
        tooltipPrimary="Track your brand’s performance score over time across selected competitors and LLMs in the specified language. Use the dropdown to select your preferred LLM."
        hasCalendarSelect
        hasLLMselect
        height={328}
        chartHasXasix
        chartHasYaxis
        chartHeight={200}
        chartHasTooltip
        chartHasMarginTop={20}
        chartHasMarginLeft={-20}
        chartHasGrid
        data={data}
      /> */}
      </PageLayout>
    </GlobalFiltersProvider>
  );
};
export default GlobalMonitoring;
