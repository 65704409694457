import { useEffect, useState } from "react";
import RolesDropdown from "../RolesDropdown/RolesDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, InputAdornment, TextField, useTheme } from "@mui/material";

const InvitePeople = ({
  setEmails,
  emails,
  setDropdownValue,
  dropdownValue,
  setIsAdmin,
  setButtonDisabled,
}) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState("");
  const [invalidEmails, setInvalidEmails] = useState([]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyDown = (e) => {
    if (["Enter", ",", " "].includes(e.key)) {
      e.preventDefault();
      const trimmedEmail = inputValue.trim().replace(/,$/, "");

      if (trimmedEmail) {
        setEmails((prev) => [...prev, trimmedEmail]);
        setInputValue("");

        setInvalidEmails((prev) =>
          validateEmail(trimmedEmail)
            ? prev.filter((email) => email !== trimmedEmail)
            : [...prev, trimmedEmail]
        );
      }
    }
  };

  const handleDelete = (emailToDelete) => {
    const updatedEmails = emails.filter((email) => email !== emailToDelete);
    const updatedInvalidEmails = invalidEmails.filter(
      (email) => email !== emailToDelete
    );
    setEmails(updatedEmails);
    setInvalidEmails(updatedInvalidEmails);
  };

  useEffect(() => {
    setButtonDisabled(invalidEmails.length > 0 || emails.length === 0);
  }, [invalidEmails]);

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setDropdownValue(value);

    if (value === "admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  return (
    <TextField
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder={emails?.length === 0 ? "name@goodie.com" : ""}
      sx={{
        width: "70%",
        "& .MuiOutlinedInput-root": {
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "8px",
          padding: "8px",
          fontSize: "14px",
          "& fieldset": {
            border: `1px solid ${theme.palette.border.secondary}`,
            borderRadius: "11px",
          },
          "&:hover fieldset": {
            border: `1px solid ${theme.palette.border.secondary}`,
          },
          "&.Mui-focused fieldset": {
            border: `1px solid ${theme.palette.border.secondary}`,
          },
        },
        "& .MuiInputBase-input": {
          flex: "1",
          minWidth: "120px",
          margin: "8px 0px",
          padding: "4px",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "gray",
        },
      }}
      InputProps={{
        startAdornment: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            {emails?.map((email, index) => (
              <Chip
                key={index}
                label={email}
                onDelete={() => handleDelete(email)}
                size="small"
                sx={{
                  color:
                    invalidEmails.includes(email) &&
                    theme.palette.text.negative,
                  borderRadius: "5px",
                  backgroundColor: invalidEmails.includes(email)
                    ? theme.palette.background.negative
                    : theme.palette.background.primary,
                  padding: "15px 4px 15px 4px",
                  fontSize: "14px",
                  "& .MuiChip-deleteIcon": {
                    color: invalidEmails.includes(email)
                      ? theme.palette.text.negative
                      : "white",
                  },
                }}
                deleteIcon={<CloseIcon />}
              />
            ))}
          </Box>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <RolesDropdown
              dropdownValue={dropdownValue}
              handleDropdownChange={handleDropdownChange}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InvitePeople;
