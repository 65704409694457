import React, { useState } from "react";
import { Box } from "@mui/material";
import SidebarButton from "../SideBarButton/SidebarButton";
import OverviewIcon from "../../../customIcons/OverviewIcon copy";
import { useParams } from "react-router-dom";
import TopicRecognitionIcon from "../../../customIcons/TopicRecognitionIcon";
import CollapsibleMenuItem from "../CollapsibleMenuItem/CollapsibleMenuItem";

const OptimzationHubButtons = ({
  isCollapsed,
  sx,
  expandedPadding = "5px 0px 5px 8px",
  borderRadius = "8px",
  isDisabled,
  featureFlags,
}) => {
  const { brandId: id } = useParams();
  const brandId = id || localStorage.getItem("brandId");
  const [openTopics, setOpenTopics] = useState();
  const workspaceData = JSON.parse(localStorage.getItem("workspace"));
  const topics = workspaceData?.topics;

  return (
    <Box sx={{ ...sx }}>
      <SidebarButton
        to={`/optimizationhub/${brandId}/overview`}
        primary="Overview"
        IconComponent={OverviewIcon}
        expandedPadding={expandedPadding}
        isCollapsed={isCollapsed}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
      />

      <CollapsibleMenuItem
        isCollapsed={isCollapsed}
        open={openTopics}
        onClick={() => setOpenTopics(!openTopics)}
        icon={TopicRecognitionIcon}
        primaryText="Topics"
        featureFlags={featureFlags}
        canAccess={featureFlags?.can_access_optimization_hub}
      >
        {topics?.map((topic, index) => (
          <SidebarButton
            key={index}
            to={`/optimizationhub/${brandId}/topic/${topic.replace(
              /\s+/g,
              "-"
            )}`}
            primary={topic}
            expandedPadding={expandedPadding}
            isCollapsed={isCollapsed}
            borderRadius={borderRadius}
            isDisabled={isDisabled}
          />
        ))}
      </CollapsibleMenuItem>
    </Box>
  );
};

export default OptimzationHubButtons;
