import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/styles";
import SourceTableCell from "./components/SourceTableCell/SourceTableCell";
import TopicTableCell from "./components/TopicTableCell";
import TypographyTableCell from "./components/TypographyTableCell/TypographyTableCell";
import HeadCells from "./components/HeadCells/HeadCells";
import ScoreTypography from "../../../AEOAssessmentReport/components/EntityRecognitionScore/components/ScoreTypography/ScoreTypography";
import RiskTableCell from "./components/RiskTableCell";
import ArrowRightIcon from "../../../../CommonComponents/customIcons/ArrowRightIcon";
import { Typography } from "@mui/material";
import StatusTableCell from "./components/StatusTableCell/StatusTableCell";
import { API_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import { useGlobalFilters } from "../../../../contexts/GlobalFiltersContext";
import AnimationLoader from "../../../../CommonComponents/AnimationLoader/AnimationLoader";
// const rows = [
//   {
//     source: "Business Insider",
//     topic: "Digital Banking App",
//     type: "Earned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "Landing page",
//     optimzation_piriority: "High risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Negative",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "social media",
//     optimzation_piriority: "High risk",
//     should_optimize: false,
//     source_status: "in progress",
//   },
//   {
//     source: "Business Insider",
//     topic: "Savings Accounts",
//     type: "Earned",
//     sentiment: "Neutral",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "blog",
//     optimzation_piriority: "Medium risk",
//     should_optimize: false,
//     source_status: "completed",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "article",
//     optimzation_piriority: "Low risk",
//     should_optimize: false,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Digital Banking App",
//     type: "Earned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "Landing page",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Negative",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "social media",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Earned",
//     sentiment: "Neutral",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "blog",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "article",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Digital Banking App",
//     type: "Earned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "Landing page",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Negative",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "social media",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Earned",
//     sentiment: "Neutral",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "blog",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "article",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Digital Banking App",
//     type: "Earned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "Landing page",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Negative",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "social media",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Earned",
//     sentiment: "Neutral",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "blog",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "article",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Digital Banking App",
//     type: "Earned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "Landing page",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Negative",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "social media",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Earned",
//     sentiment: "Neutral",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "blog",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
//   {
//     source: "Business Insider",
//     topic: "Online Banking",
//     type: "Owned",
//     sentiment: "Positive",
//     mentions: "4",
//     influence: "77",
//     risk: "55",
//     category: "article",
//     optimzation_piriority: "Low risk",
//     should_optimize: true,
//     source_status: "inbox",
//   },
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const SourceTrackingTable = ({ isOverview, date }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("source");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { brandId } = useParams();

  const fetchData = async (page, rowsPerPage, order, orderBy) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/aeo/${brandId}/source-tracking/`,
        {
          params: {
            page: page + 1,
            page_size: rowsPerPage,
            ordering: order === "asc" ? orderBy : `-${orderBy}`,
          },
        }
      );
      setRows(response.data.results); // Assuming the API returns `results`
      setTotalCount(response.data.count); // Assuming the API returns `count`
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, rowsPerPage, order, orderBy);
  }, [page, rowsPerPage, order, orderBy, date]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      <TableContainer
        sx={{
          overflow: "hidden",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Table
          sx={{ minWidth: 750, tableLayout: "auto" }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <HeadCells
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={totalCount}
            isOverview={isOverview}
          />
          <TableBody>
            {!loading && rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.border.quaternary}`,
                    "& td": {
                      borderBottom: `1px solid ${theme.palette.border.quaternary}`,
                    },
                    "&:hover .should-optimize-typography": {
                      backgroundColor: theme.palette.background.quaternary,
                      borderRadius: "30px",
                      padding: "5px 8px",
                      maxWidth: "fit-content",
                    },
                  }}
                >
                  <TableCell align="left">
                    <SourceTableCell
                      value={row.source}
                      category={row.category}
                      navigate={row.source_url}
                    />
                  </TableCell>
                  {isOverview ? (
                    <TableCell align="left">
                      <TopicTableCell topic={row.topic} />
                    </TableCell>
                  ) : (
                    <TableCell align="left">
                      <StatusTableCell status={row.source_status} />
                    </TableCell>
                  )}
                  <TableCell align="left">
                    <TypographyTableCell value={row.type} />
                  </TableCell>
                  <TableCell align="left">
                    <ScoreTypography
                      iconType="face"
                      score={row.sentiment}
                      fontSize={12}
                      fontWeight={400}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TypographyTableCell value={row.brand_mentions} />
                  </TableCell>
                  <TableCell align="left">
                    <TypographyTableCell value={row.influence_score} />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ color: theme.palette.text.primary, fontSize: "12px" }}
                  >
                    <RiskTableCell
                      value={row.impact_score}
                      piriority={row.optimzation_piriority}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: "12px",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 0.5,
                        borderRadius: "30px",
                        padding: "5px 8px",
                        maxWidth: "fit-content",
                        cursor: "pointer",
                      }}
                      className="should-optimize-typography"
                    >
                      {row.should_optimize ? "Optimize" : "View"}{" "}
                      <ArrowRightIcon color={theme.palette.text.primary} />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: theme.palette.background.secondary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                }}
              >
                <AnimationLoader />
              </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
export default SourceTrackingTable;
