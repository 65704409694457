import { Box, TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UnfoldMoreSharpIcon from "@mui/icons-material/UnfoldMoreSharp";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

const HeadCells = ({ order, orderBy, onRequestSort, isOverview }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();
  const cells = [
    {
      id: "source",
      numeric: false,
      label: "Source",
    },
    {
      id: isOverview ? "topic" : "status",
      numeric: true,
      label: isOverview ? "Topic" : "Status",
      tooltipMessage: "here",
    },
    { id: "type", numeric: true, label: "Type", tooltipMessage: "here" },
    {
      id: "sentiment",
      numeric: true,
      label: "Sentiment",
      tooltipMessage: "here",
    },
    {
      id: "mentions",
      numeric: true,
      label: "Mentions",
      tooltipMessage: "here",
    },
    {
      id: "influence",
      numeric: true,
      label: "Influence",
      tooltipMessage: "here",
    },
    { id: "risk", numeric: true, label: "Risk", tooltipMessage: "here" },
    { id: "action", numeric: true, label: "Action", tooltipMessage: "here" },
  ];
  return (
    <TableHead
      style={{
        backgroundColor: theme.palette.background.tertiary,
        padding: 0,
      }}
    >
      <TableRow>
        {cells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.text.primary,
              borderRight:
                index !== cells.length - 1
                  ? `1px solid ${theme.palette.border.tertiary}`
                  : "none",
              borderBottom: "none",
              padding: "9px",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ display: "flex", flexDirection: "row", columnGap: 0.5 }}
              IconComponent={() => (
                <UnfoldMoreSharpIcon
                  sx={{ color: "text.quaternary", width: 17, height: 17 }}
                  onClick={createSortHandler(headCell.id)}
                />
              )}
            >
              {headCell.label}

              {headCell.tooltipMessage && (
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {headCell.tooltipMessage}
                    </div>
                  }
                >
                  <InfoOutlinedIcon
                    sx={{ color: "text.tertiary", width: 15, height: 15 }}
                  />
                </Tooltip>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default HeadCells;
