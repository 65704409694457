import { useTheme } from "@emotion/react";
import CardWithIcon from "../../../../CommonComponents/CardWithIcon/CardWithIcon";
import SentimentIcon from "../../../../CommonComponents/customIcons/SentimentIcon";
import MultipleValuesWheelGrid from "../../../../CommonComponents/MultipleValuesWheelGrid";

const SentimentAnalysis = ({ values }) => {
  const theme = useTheme();
  const COLORS = ["#66C285", "#FFE066", "#EF6666"];

  return (
    <CardWithIcon
      title={`Current Sentiment Analysis`}
      height={"100%"}
      tooltipMessagePrimary="Shows whether the brand is recognized as an entity by the LLM, the sentiment associated with its presence (positive, neutral, or negative), and its authority score (0-100), reflecting the brand’s influence and credibility."
      icon={<SentimentIcon color={theme.palette.text.primary} />}
    >
      <MultipleValuesWheelGrid
        wheelCenterText={values.positive}
        data={[
          { label: "Positive", value: values.positive },
          { label: "Neutral", value: values.neutral },
          { label: "Negative", value: values.negative },
        ]}
        wheelColors={COLORS}
      />
    </CardWithIcon>
  );
};
export default SentimentAnalysis;
