import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import SentimentSatisfiedAltSharpIcon from "@mui/icons-material/SentimentSatisfiedAltSharp";
import SentimentDissatisfiedSharpIcon from "@mui/icons-material/SentimentDissatisfiedSharp";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const getColors = (score, theme) => {
  if (score >= 80 || score === "Yes" || score === "Positive") {
    return [theme.palette.background.positive, theme.palette.text.positive];
  } else if (score >= 50 || score === "Neutral") {
    return [theme.palette.background.neutral, theme.palette.text.neutral];
  } else if (
    !!score &&
    (score < 50 || score === "Negative" || score === "No")
  ) {
    return [theme.palette.background.negative, theme.palette.text.negative];
  } else {
    return [theme.palette.background.brand20, theme.palette.text.brandbold];
  }
};

const getIcon = (score, iconType) => {
  const isPositive = score >= 80 || score === "Yes" || score === "Positive";
  const isNeutral = score >= 50 || score === "Neutral";
  const isNegative = score < 50 || score === "Negative";
  const notRecognised = " " || "";

  if (iconType === "check") {
    return isPositive || isNeutral ? (
      <CheckCircleOutlineSharpIcon fontSize="18px" />
    ) : (
      <CancelOutlinedIcon fontSize="18px" />
    );
  }

  if (isPositive) {
    return <SentimentSatisfiedAltSharpIcon fontSize="18px" />;
  } else if (isNeutral) {
    return <SentimentSatisfiedAltSharpIcon fontSize="18px" />;
  } else if (isNegative) {
    return <SentimentDissatisfiedSharpIcon fontSize="18px" />;
  } else if (notRecognised) {
    return;
  }
};

const ScoreTypography = ({
  score,
  iconType,
  fontSize = 15,
  fontWeight = 600,
}) => {
  const theme = useTheme();

  return (
    <Typography
      style={{
        maxWidth: "fit-content",
        borderRadius: 11.65,
        display: "flex",
        flexDirection: "row",
        columnGap: 5,
        fontSize: fontSize,
        fontWeight: fontWeight,
        padding: "3px 7px",
        alignItems: "center",
        backgroundColor: getColors(score, theme)[0],
        color: getColors(score, theme)[1],
      }}
    >
      {iconType && getIcon(score, iconType)}{" "}
      {!!score ? score : "Not Recognized"}
    </Typography>
  );
};
export default ScoreTypography;
