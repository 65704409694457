import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import { useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_URL, ML_AEO_URL } from "../../../../../../api";
import GlobalIcon from "../../../../../../CommonComponents/customIcons/GlobalIcon";
import { dummyAEOSourceData } from "../../../../../AEO/consts/consts";
import RiskIcon from "../../../../../../CommonComponents/customIcons/RiskIcon";
import RiskAudit from "../../../../components/RiskAudit/RiskAudit";

const RiskAuditCard = () => {
  const theme = useTheme();
  const {
    date: globalDate,
    initialDate: globalInitialDate,
    llm,
    noData,
  } = useGlobalFilters();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const [data, setData] = useState([]);
  const { brandId, topicName } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedLLM, setSelectedLLM] = useState(llm);
  const topic = topicName ? topicName.replace(/-/g, " ") : "";

  useEffect(() => {
    setSelectedLLM(llm);
  }, [llm]);

  useEffect(() => {
    const start_date = date[0] && date[0].format("YYYY-MM-DD");
    const end_date = date[1] && date[1].format("YYYY-MM-DD");
    const url = topic
      ? `${API_URL}/aeo/${brandId}/source-risk-audit/?llm=${selectedLLM}&startDate=${start_date}&endDate=${end_date}&topic=${encodeURIComponent(
          topic
        )}`
      : `${API_URL}/aeo/${brandId}/source-risk-audit/?llm=${selectedLLM}&startDate=${start_date}&endDate=${end_date}`;

    setLoading(true);
    axios
      .get(url)
      .then((response) => {
        setLoading(false);

        const data = response.data;
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }, [brandId, selectedLLM, topic, date]);

  return (
    <ChartCard
      icon={<RiskIcon color={theme.palette.text.primary} />}
      title="Risk Audit"
      tooltipMessagePrimary="Shows your brand’s visibility score across various regions and selected LLM. Higher scores indicate stronger brand presence in each country or region."
      tooltipMessageSecondary="All score out of 100."
      height={276}
      isLoading={loading}
      isError={error}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      hasCalendarSelect
      rowGap={5}
      hasLLMselect
      selectedLLM={selectedLLM}
      setSelectedLLM={setSelectedLLM}
    >
      <RiskAudit values={data} />
    </ChartCard>
  );
};
export default RiskAuditCard;
