import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import { useTheme } from "@mui/material";
import CardWithIcon from "../../../../CommonComponents/CardWithIcon/CardWithIcon";
import ComparisionTable from "../ComparisonTable/ComparisionTable";

const OwnedComparision = ({ scores, brands }) => {
  const theme = useTheme();

  return (
    <CardWithIcon
      title={`Comparison Assessment Table (Owned Sources)`}
      height={"100%"}
      tooltipMessagePrimary="Owned sources refer to platforms under your brand's control, such as your website, which can be easily updated or modified. "
      tooltipMessageSecondary="This table evaluates your brand's and competitors' owned sources across key criteria that influence AI visibility and discoverability."
      icon={<LayersOutlinedIcon color={theme.palette.text.primary} />}
    >
      <ComparisionTable scores={scores} brands={brands} />
    </CardWithIcon>
  );
};
export default OwnedComparision;
