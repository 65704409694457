import React from "react";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import CustomeTooltip from "../LineChart/Components/Tooltip/Tooltip";
import { useTheme } from "@mui/material";

const applyOpacity = (color, opacity) => {
  const rgbaColor = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  return rgbaColor
    ? `rgba(${parseInt(rgbaColor[1], 16)}, ${parseInt(
        rgbaColor[2],
        16
      )}, ${parseInt(rgbaColor[3], 16)}, ${opacity})`
    : color;
};

const BarChart = ({
  hasXaxis = true,
  hasYaxis,
  hasTooltip,
  hasBackground = true,
  hasGrid,
  data,
  xAxisDataKey = "brand",
  height = 140,
}) => {
  const theme = useTheme();
  const tickColor = theme.palette.text.tertiary;
  const textColor = theme.palette.text.chiptext;
  return (
    <ResponsiveContainer width="100%" height={height}>
      <RechartsBarChart data={data} barSize={200}>
        {hasGrid && <CartesianGrid vertical={false} stroke="#F2F4F7" />}

        {hasXaxis && (
          <XAxis
            dataKey={xAxisDataKey}
            padding={{ right: 10 }}
            axisLine={false}
            tickLine={false}
            tick={{ fill: tickColor, fontSize: 12, fontWeight: 450 }}
          />
        )}
        {hasYaxis && (
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: tickColor, fontSize: 12, fontWeight: 450 }}
          />
        )}
        {hasTooltip && <Tooltip content={<CustomeTooltip />} cursor={false} />}

        <Bar dataKey="value" radius={[4, 4, 0, 0]} stackId="a">
          <LabelList
            dataKey="value"
            position="center"
            fill={textColor}
            style={{ fontSize: 12, fontWeight: 500 }}
            formatter={(value) => Math.round(value)}
          />
        </Bar>

        {hasBackground && (
          <Bar
            dataKey="backgroundValue"
            radius={[4, 4, 0, 0]}
            stackId="a"
            data={data.map((item) => ({
              ...item,
              fill: applyOpacity(item.fill, 0.3),
            }))}
          />
        )}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
