import Logo from "../../../../assets/images/fullLogo.png";
import EastIcon from "@mui/icons-material/East";
import ChipButton from "../../../../CommonComponents/ChipButton/ChipButton";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
const Header = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <img src={Logo} alt="Logo" width={186} />
      <ChipButton
        iconAfter={<EastIcon />}
        label="Get Started"
        backgroundColor="#C7F565"
        textColor={theme.palette.text.chiptext}
        hoverBackgroundColor="#EBFFC1"
        hoverTextColor={theme.palette.text.chiptext}
        hoverBorderColor={theme.palette.text.brandbold}
        onClick={() =>
          (window.location.href = `https://www.higoodie.com/#signup`)
        }
      />
    </Box>
  );
};
export default Header;
