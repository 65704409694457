import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import CardWithIcon from "../../../../CommonComponents/CardWithIcon/CardWithIcon";
import VisibilityIcon from "../../../../CommonComponents/customIcons/VisibilityIcon";
import PercentageWheel from "../../../../CommonComponents/PercentageWheel/PercentageWheel";

const BrandVisibility = ({ percentage }) => {
  const theme = useTheme();

  return (
    <CardWithIcon
      title={`Brand Visibility`}
      height={'100%'}
      tooltipMessagePrimary="Shows brand visibility score by the LLM. Higher scores indicate greater brand prominence within the model."
      tooltipMessageSecondary="Score is out of 100."
      icon={<VisibilityIcon color={theme.palette.text.primary} />}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 20,
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: 20,
        }}
      >
        <div style={{ width: "100%" }}>
          <PercentageWheel
            percentage={percentage}
            width={130}
            height={130}
            textColorMatchPath
          />
        </div>
        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
          Your Visibility Score (0-100) shows how likely your brand is to be
          surfaced by this LLM via search.
        </Typography>
      </Box>
    </CardWithIcon>
  );
};
export default BrandVisibility;
