import ChartCard from "../../../../../AEO/components/ChartCard/ChartCard";
import { useTheme } from "@mui/material";
import { useGlobalFilters } from "../../../../../../contexts/GlobalFiltersContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_URL, ML_AEO_URL } from "../../../../../../api";
import { dummyAEOSourceData } from "../../../../../AEO/consts/consts";
import MultipleValuesWheelGrid from "../../../../../../CommonComponents/MultipleValuesWheelGrid";
import SourceBreakDownIcon from "../../../../../../CommonComponents/customIcons/SourceBreakdownIcon";



const VisibilityAcrossRegions = () => {
  const theme = useTheme();
  const { date: globalDate, initialDate: globalInitialDate } =
    useGlobalFilters();
  const [date, setDate] = useState(globalDate);
  const [initialDate, setInitialDate] = useState(globalInitialDate);
  const [data, setData] = useState([]);
  const { brandId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (date.length > 0) {
      const start_date = date[0] && date[0].format("YYYY-MM-DD");
      const end_date = date[1] && date[1].format("YYYY-MM-DD");

      setLoading(true);
      axios
        .get(
          `${API_URL}/aeo/${brandId}/source-breakdown/?startDate=${start_date}&endDate=${end_date}`
        )
        .then((response) => {
          setLoading(false);
          const transformedData = response.data.map((item) => ({
            label: item.type,
            value: item.value,
          }));
          setData(transformedData);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
    }
  }, [brandId, date]);

  return (
    <ChartCard
      icon={<SourceBreakDownIcon color={theme.palette.text.primary} />}
      title="Source Type Breakdown"
      tooltipMessagePrimary="Shows your brand’s visibility score across various regions and selected LLM. Higher scores indicate stronger brand presence in each country or region."
      tooltipMessageSecondary="All score out of 100."
      height={356}
      isLoading={loading}
      isError={error}
      setDate={setDate}
      date={date}
      initialDate={initialDate}
      hasCalendarSelect
      rowGap={5}
    >
      <MultipleValuesWheelGrid
        wheelColors={[
          theme.palette.text.brand,
          theme.palette.brands.F,
          theme.palette.brands.D,
        ]}
        data={data}
        wheelWidth={183}
        wheelHeight={183}
        marginTop="50px"
        wheelInnerRaduis={66}
        wheelOuterRaduis={80}
        justifyContent="center"
      />
    </ChartCard>
  );
};
export default VisibilityAcrossRegions;
