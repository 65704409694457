import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  Divider,
  IconButton,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import HomeIcon from "../customIcons/Home";
import Doc from "../customIcons/doc";
import SidebarButton from "./components/SideBarButton/SidebarButton";
import GenerateBlogModal from "../../pages/Dashboard/components/GenerateBlogModal";
import CollapseIcon from "../customIcons/CollapseIcon";
import { API_URL } from "../../api";
import axios from "axios";
import WorkspacesDropdown from "./components/WorkspacesDropdown";
import SettingsButton from "./components/SettingsButton/SettingsButton";
import WorkspaceImage from "./components/WorkspaceImage/WorkspaceImage";
import WorkspaceName from "./components/WorkspaceName";
import CustomTooltip from "./components/CustomTooltip";
import ElectricIcon from "../customIcons/ElectricIcon";

import AIVisibilityIcon from "../customIcons/AIVisibilityIcon";
import AudioModalityIcon from "../customIcons/AudioModalityIcon";
import AIVisibilityButtons from "./components/AIVisibilityButtons/AIVisibilityButtons";
import DarkIcon from "../customIcons/DarkIcon";
import LightIcon from "../customIcons/LightIcon";
import { useThemeContext } from "../../Theme/ThemeContext";
import OptimzationHubButtons from "./components/OptimizationHubButtons/OptimzationHubButtons";
import CollapsibleMenuItem from "./components/CollapsibleMenuItem/CollapsibleMenuItem";
import CollapsedTooltip from "./components/CollapsedTooltip/CollapsedTooltip";

const Sidebar = ({ onCollapseChange, setLoading }) => {
  const [openAIVisibility, setOpenAIVisibility] = useState(false);
  const [openOptimzationHub, setOpenOptimzationHub] = useState(false);

  const [openBlogDrawer, setOpenBlogDrawer] = useState(false);
  const { brandId: id } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBrandDnaCompleted, setisBrandDnaCompleted] = useState(false);
  const [featureFlags, setFeatureFlags] = useState();

  const [workspaceName, setWorkspaceName] = useState("Workspaces");
  const [workspaceImageUrl, setWorkspaceImageUrl] = useState("");

  const open = Boolean(anchorEl);
  const brandId = id || localStorage.getItem("brandId");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const workspaceData = JSON.parse(localStorage.getItem("workspace"));
  const userBrands = JSON.parse(localStorage.getItem("loggedInUser"))?.brands;
  const { darkMode, toggleTheme } = useThemeContext();

  useEffect(() => {
    if (userBrands?.length > 0) {
      const currentWorkspace = userBrands?.find(
        (brand) => parseInt(brand.id) === parseInt(brandId)
      );
      setWorkspaceName(currentWorkspace?.name);
      setWorkspaceImageUrl(currentWorkspace?.image);
    }
  }, [workspaceData, userBrands, brandId]);

  useEffect(() => {
    axios
      .get(`${API_URL}/brands/${brandId}/overview`)
      .then((response) => {
        setLoading(false);

        const workspaceData = response.data;
        const feature_flags = workspaceData.feature_flags;

        localStorage.setItem("workspace", JSON.stringify(workspaceData));
        setisBrandDnaCompleted(workspaceData.is_brand_dna_completed);

        localStorage.setItem("feature_flags", JSON.stringify(feature_flags));
        setFeatureFlags(feature_flags);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user data:", error);
      });
  }, [brandId]);

  const handleOpenAiVisibility = (section) => {
    if (section === "AIVisibility" && featureFlags?.can_access_aeo) {
      setOpenAIVisibility(!openAIVisibility);
    }
  };

  const handleOpenOptimizationHub = (section) => {
    if (
      section === "OptimzationHub" &&
      featureFlags?.can_access_optimization_hub
    ) {
      setOpenOptimzationHub(!openOptimzationHub);
    }
  };
  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
    onCollapseChange(!isCollapsed);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: isCollapsed ? 55 : 304,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isCollapsed ? 55 : 304,
          boxSizing: "border-box",
          backgroundColor: "background.secondary",
          p: isCollapsed ? "16px 10px" : "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: isCollapsed ? "0px" : "30px",
            flexDirection: isCollapsed ? "column" : "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pb: isCollapsed ? "20px" : "0px",
              maxWidth: "73%",
            }}
          >
            <CustomTooltip
              title={
                isCollapsed ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <WorkspaceImage
                      isCollapsed={isCollapsed}
                      inTooltip={true}
                      name={workspaceName}
                      image={workspaceImageUrl}
                    />
                    <WorkspaceName
                      workspaceName={workspaceName}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      inTooltip={true}
                    />
                  </Box>
                ) : (
                  ""
                )
              }
              placement="right"
              disableHoverListener={!isCollapsed}
              bgColor="#1A1A1A"
              arrow
            >
              <WorkspaceImage
                isCollapsed={isCollapsed}
                name={workspaceName}
                image={workspaceImageUrl}
              />
            </CustomTooltip>

            {!isCollapsed && (
              <WorkspaceName
                workspaceName={workspaceName}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                inTooltip={false}
              />
            )}
          </Box>
          <box>
            <SettingsButton
              isCollapsed={isCollapsed}
              handleSettingsClick={() =>
                navigate(`/workspace-settings/${brandId}`)
              }
            />
            {!isCollapsed && (
              <IconButton
                sx={{
                  color: "text.tertiary",
                  p: "0px",
                }}
                onClick={handleCollapseClick}
              >
                <CollapseIcon
                  sx={{ fontSize: 24, color: theme.palette.text.tertiary }}
                />
              </IconButton>
            )}
          </box>
        </Box>
        {!isCollapsed && <Divider sx={{ borderColor: "border.tertiary" }} />}

        <Box
          sx={{
            pb: isCollapsed ? "0px" : "15px",
            pt: isCollapsed ? "0px" : "15px",
          }}
        >
          <List
            component="nav"
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 0,
              pt: isCollapsed ? "27px" : "0px",
            }}
          >
            <SidebarButton
              to={`/dashboard/${brandId}/`}
              primary="Dashboard"
              IconComponent={HomeIcon}
              isCollapsed={isCollapsed}
              sx={{ mb: isCollapsed ? "27px" : "0px" }}
              collapsedPadding="5px"
              expandedPadding="8px"
            />
            <SidebarButton
              primary="AI Content Writer"
              IconComponent={Doc}
              handleClick={() => setOpenBlogDrawer(true)}
              isCollapsed={isCollapsed}
              sx={{
                mb: isCollapsed ? "27px" : "0px",
              }}
              collapsedPadding="5px"
              expandedPadding="8px"
              isDisabled={!featureFlags?.can_access_content_writer}
            />
            <CollapsibleMenuItem
              isCollapsed={isCollapsed}
              featureFlags={featureFlags}
              open={openAIVisibility}
              onClick={() => handleOpenAiVisibility("AIVisibility")}
              icon={AIVisibilityIcon}
              primaryText="AI Visibility"
              canAccess={featureFlags?.can_access_aeo}
            >
              <AIVisibilityButtons isDisabled={!featureFlags?.can_access_aeo} />
            </CollapsibleMenuItem>

            <CollapsibleMenuItem
              isCollapsed={isCollapsed}
              featureFlags={featureFlags}
              open={openOptimzationHub}
              onClick={() => handleOpenOptimizationHub("OptimzationHub")}
              icon={ElectricIcon}
              primaryText="AI Optimization"
              canAccess={featureFlags?.can_access_optimization_hub}
            >
              <OptimzationHubButtons
                isDisabled={!featureFlags?.can_access_optimization_hub}
                featureFlags={featureFlags}
              />
            </CollapsibleMenuItem>

            <CollapsedTooltip
              isCollapsed={isCollapsed}
              title="AI Visibility"
              icon={AIVisibilityIcon}
              isDisabled={!featureFlags?.can_access_aeo}
            >
              <AIVisibilityButtons
                sx={{ width: "156px" }}
                expandedPadding="8px 12px"
                borderRadius="0px"
                isDisabled={!featureFlags?.can_access_aeo}
              />
            </CollapsedTooltip>

            <CollapsedTooltip
              isCollapsed={isCollapsed}
              title="AI Optimization"
              icon={ElectricIcon}
              isDisabled={!featureFlags?.can_access_optimization_hub}
            >
              <OptimzationHubButtons
                expandedPadding="8px"
                sx={{ width: "240px" }}
                isDisabled={!featureFlags?.can_access_optimization_hub}
                featureFlags={featureFlags}
              />
            </CollapsedTooltip>
            <SidebarButton
              // to={`/workspace-settings/${brandId}/brand-dna-overview`}
              primary="Audio Modality"
              IconComponent={AudioModalityIcon}
              isCollapsed={isCollapsed}
              collapsedPadding="5px"
              expandedPadding="8px"
              isDisabled={!featureFlags?.can_access_audio_modality}
            />
          </List>
        </Box>

        <GenerateBlogModal
          openBlogDrawer={openBlogDrawer}
          setOpenBlogDrawer={setOpenBlogDrawer}
          brandId={brandId}
        />
        <WorkspacesDropdown
          anchorEl={anchorEl}
          open={open}
          setAnchorEl={setAnchorEl}
          userBrands={userBrands}
        />
      </box>
      <box>
        <IconButton
          sx={{
            display: isCollapsed ? "block" : "none",
            p: "0px",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
          onClick={handleCollapseClick}
        >
          <CollapseIcon sx={{ fontSize: 24 }} />
        </IconButton>
        <SidebarButton
          primary={darkMode ? "Light" : "Dark"}
          IconComponent={darkMode ? LightIcon : DarkIcon}
          handleClick={toggleTheme}
          isCollapsed={isCollapsed}
          disableHover={true}
          collapsedPadding="8px"
          expandedPadding="16px"
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            justifyContent: isCollapsed ? "center" : "flex-start",
          }}
        />
      </box>
    </Drawer>
  );
};

export default Sidebar;
