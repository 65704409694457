import { Typography, useTheme } from "@mui/material";
import { getSentimentColors } from "../../../../../AEO/utils/DataUtils";

const ScoreMovementTypography = ({
  score,
  movement,
  padding = 0,
  borderRadius = "6px",
  backgroundColor,
  color,
}) => {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        columnGap: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: backgroundColor || getSentimentColors(score, theme)[0],
        color: color || getSentimentColors(score, theme)[1],
        fontSize: 28,
        fontWeight: 600,
        borderRadius: borderRadius,
        padding: padding,
      }}
    >
      {score}
      <Typography
        sx={{
          color: color || getSentimentColors(score, theme)[1],
          backgroundColor:
            backgroundColor || getSentimentColors(score, theme)[0],
          padding: "0px 4px",
          borderRadius: "7px",
          fontSize: "14px",
        }}
      >
        + {movement}
      </Typography>
    </Typography>
  );
};
export default ScoreMovementTypography;
