import React from "react";
import { Box } from "@mui/material";
import SidebarButton from "../SideBarButton/SidebarButton";
import VisibilityIcon from "../../../customIcons/VisibilityIcon";
import PerformanceIcon from "../../../customIcons/PerformanceIcon";
import SentimentIcon from "../../../customIcons/SentimentIcon";
import GlobalIcon from "../../../customIcons/GlobalIcon";
import ComparisonIcon from "../../../customIcons/ComparisonIcon";
import OverviewIcon from "../../../customIcons/OverviewIcon copy";
import { useParams } from "react-router-dom";

const AIVisibilityButtons = ({
  isCollapsed,
  sx,
  expandedPadding = "5px 0px 5px 8px",
  borderRadius = "8px",
  isDisabled,
}) => {
  const { brandId: id } = useParams();
  const brandId = id || localStorage.getItem("brandId");

  return (
    <Box sx={{ ...sx }}>
      <SidebarButton
        to={`/ai-visibility/${brandId}/dashboard`}
        primary="Overview"
        IconComponent={OverviewIcon}
        expandedPadding={expandedPadding}
        isCollapsed={isCollapsed}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
      />
      <SidebarButton
        to={`/ai-visibility/${brandId}/visibility-analysis`}
        primary="Visibility"
        IconComponent={VisibilityIcon}
        expandedPadding={expandedPadding}
        isCollapsed={isCollapsed}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
      />
      <SidebarButton
        to={`/ai-visibility/${brandId}/performance-analysis`}
        primary="Performance"
        IconComponent={PerformanceIcon}
        expandedPadding={expandedPadding}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
      />
      <SidebarButton
        to={`/ai-visibility/${brandId}/sentiment-analysis`}
        primary="Sentiment"
        IconComponent={SentimentIcon}
        expandedPadding={expandedPadding}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
      />
      <SidebarButton
        to={`/ai-visibility/${brandId}/comparison-analysis`}
        primary="Comparison"
        IconComponent={ComparisonIcon}
        expandedPadding={expandedPadding}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
      />
      <SidebarButton
        to={`/ai-visibility/${brandId}/global-monitoring`}
        primary="Global"
        IconComponent={GlobalIcon}
        expandedPadding={expandedPadding}
        borderRadius={borderRadius}
        isDisabled={isDisabled}
      />
    </Box>
  );
};

export default AIVisibilityButtons;
