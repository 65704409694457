import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../../../api";
import { useParams } from "react-router-dom";
import PromptCounter from "../../../../CommonComponents/PromptCounter";

const MentionCount = ({ loading, prompt }) => {
  return (
    <PromptCounter
      title={"Mention Count"}
      prompt={prompt}
      loading={loading}
      tooltipMessage={
        "Represents the total number of prompts indexed as 'AI Beats,' reflecting API queries across LLMs and RAG during Analysis Generation."
      }
    />
  );
};
export default MentionCount;
